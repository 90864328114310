import type { ReactNode } from 'react';
import { createElement } from 'react';
import { clsx } from 'clsx';

import type { BrandType } from 'src/components/Brand';
import { Brand } from 'src/components/Brand';

import {
  PepitaButton,
  type PepitaButtonProps,
} from 'src/libs/ui/pepita-button';

import { Title } from './Title';

import css from './index.module.scss';

export type HeroProps = {
  children?: ReactNode;
  customClass?: string;
  size?: 'default' | 'small' | 'big' | 'fluid';
  contentSmall?: boolean;
  light?: boolean;
  brand?: BrandType;
  brandLink?: string;
  headline?: string;
  showSvgHeadline?: boolean;
  onClickText?: () => void;
  subtitle?: string;
  text?: string;
  btn?: string;
  btnStyle?: PepitaButtonProps['variant'];
};

export function Hero({
  children,
  customClass,
  size = 'default',
  contentSmall,
  light,
  brand,
  brandLink,
  headline,
  showSvgHeadline,
  onClickText,
  subtitle,
  text,
  btn,
  btnStyle,
}: HeroProps) {
  const titleElement = headline ? (
    <Title
      showSvgHeadline={showSvgHeadline}
      headline={headline}
      onClickText={onClickText ? () => onClickText() : undefined}
      customClass={clsx(
        showSvgHeadline
          ? css['in-heroBrand__titleSvg']
          : css['nd-heroBrand__title'],
        Boolean(onClickText) && css['in-hero__title--link']
      )}
    />
  ) : null;

  return (
    <div
      className={clsx(
        css['in-hero'],
        css['nd-hero'],
        size !== 'default' && css[`nd-hero--${size}`],
        light && css['nd-hero--light'],
        customClass
      )}
    >
      <div
        className={clsx(
          css['nd-hero__content'],
          contentSmall && css['nd-hero__content--small']
        )}
      >
        {brand ? (
          <div className={css['nd-heroBrand']} data-cy="hero-brand">
            {brandLink ? (
              <>
                <a href={brandLink} className={css['nd-heroBrand__link']}>
                  {createElement(Brand, {
                    ...brand,
                    svgCustomClass: clsx(
                      css['nd-heroBrand__logo'],
                      brand.svgCustomClass
                    ),
                  })}
                </a>
                {titleElement}
              </>
            ) : (
              <>
                {createElement(Brand, {
                  ...brand,
                  svgCustomClass: clsx(
                    css['nd-heroBrand__logo'],
                    brand.svgCustomClass
                  ),
                })}
                {titleElement}
              </>
            )}
          </div>
        ) : (
          titleElement
        )}
        {subtitle && <div className={css['nd-hero__subtitle']}>{subtitle}</div>}
        {text && <div className={css['nd-hero__text']}>{text}</div>}
        {btn && (
          <PepitaButton
            customClass={css['nd-hero__button']}
            variant={btnStyle ?? undefined}
          >
            {btn}
          </PepitaButton>
        )}
        {children}
      </div>
    </div>
  );
}
