import { memo } from 'react';
import { clsx } from 'clsx';

import { GeographySearch } from 'src/components/GeographySearch';
import { ReactGeographySearch } from 'src/components/ReactGeographySearch';

import { isFeatureEnabled } from 'src/config/features-toggle';
import type { fieldConfigType } from '../config/formConfigTypes';

import { AutocompleteField } from './AutocompleteField';
import { InputField } from './InputField';
import { InternationalTypologyField } from './InternationalTypologyField';
import { RangeField } from './RangeField';
import { SelectField } from './SelectField';
import { TypologyField } from './TypologyField';
import { TypologyResidentialField } from './TypologyResidentialField';

const searchMetroDisabled = isFeatureEnabled('SEARCH_NO_METRO');

export const FormField = memo(function FormField({
  fieldConfig,
  customClass,
  containerCustomClass,
  iconCustomClass,
  error,
  controlCustomClass,
  selectedClass,
  onChange,
}: {
  fieldConfig: fieldConfigType;
  customClass?: string;
  containerCustomClass?: string;
  iconCustomClass?: string;
  error?: boolean;
  controlCustomClass?: string;
  selectedClass?: string;
  onChange: (label: string, value: string | string[] | number | null) => void;
}) {
  switch (fieldConfig.type) {
    case 'input': {
      return (
        <InputField
          fieldConfig={fieldConfig}
          customClass={customClass}
          selectedClass={selectedClass}
          onChange={onChange}
        />
      );
    }

    case 'category': {
      return (
        <TypologyField
          fieldConfig={fieldConfig}
          customClass={customClass}
          controlCustomClass={controlCustomClass}
          selectedClass={selectedClass}
          onChange={onChange}
        />
      );
    }

    case 'categoryResidential': {
      return (
        <TypologyResidentialField
          fieldConfig={fieldConfig}
          customClass={customClass}
          controlCustomClass={controlCustomClass}
          selectedClass={selectedClass}
          onChange={onChange}
        />
      );
    }

    case 'categoryInternational': {
      return (
        <InternationalTypologyField
          fieldConfig={fieldConfig}
          customClass={customClass}
          controlCustomClass={controlCustomClass}
          selectedClass={selectedClass}
          onChange={onChange}
        />
      );
    }

    case 'range': {
      return (
        <RangeField
          fieldConfig={fieldConfig}
          customClass={customClass}
          selectedClass={selectedClass}
          onChange={onChange}
        />
      );
    }

    case 'select': {
      return (
        <SelectField
          fieldConfig={fieldConfig}
          customClass={customClass}
          selectedClass={selectedClass}
          onChange={onChange}
        />
      );
    }

    case 'place': {
      return (
        <div className={customClass}>
          {isFeatureEnabled('REACT_GEOGRAPHY_SEARCH') ? (
            <ReactGeographySearch
              featureToggles={{
                metroSearchEnabled: fieldConfig.metroEnabled,
                regionSearchEnabled: fieldConfig.regionSearchEnabled,
                countrySearchEnabled: fieldConfig.countrySearchEnabled,
                internationalSearchEnabled: fieldConfig.internationalEnabled,
                labelsForCountry: isFeatureEnabled(
                  'INTERNATIONAL_LABELS_ENABLED'
                ),
              }}
              error={error}
              customClass={containerCustomClass}
            />
          ) : (
            <GeographySearch
              drawEnabled={fieldConfig.drawEnabled}
              metroEnabled={fieldConfig.metroEnabled}
              regionSearchEnabled={fieldConfig.regionSearchEnabled}
              countrySearchEnabled={fieldConfig.countrySearchEnabled}
              internationalEnabled={fieldConfig.internationalEnabled}
              autorefill={fieldConfig.autorefill}
              onAutoSubmit={fieldConfig.onAutoSubmit}
              searchMetroDisabled={searchMetroDisabled}
              error={error}
              customClass={containerCustomClass}
            />
          )}
        </div>
      );
    }

    case 'autocomplete': {
      const autocompleteFieldConfig = {
        ...fieldConfig,
        icon: {
          name: 'lens',
          customClass: clsx(
            iconCustomClass,
            error && `${iconCustomClass}--error`
          ),
          isAlternate: error,
          nameAlternate: 'exclamation-mark-circle--active',
        },
      };

      return (
        <div className={customClass}>
          <div className={containerCustomClass}>
            <AutocompleteField
              fieldConfig={autocompleteFieldConfig}
              onChange={onChange}
            />
          </div>
        </div>
      );
    }

    default:
      return null;
  }
});
