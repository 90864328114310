import { clsx } from 'clsx';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObject,
  PepitaMediaObjectContent,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';

import type { AssociationItem, FranchisingItem } from 'src/types/homepage';

import css from './styles.module.scss';

interface GroupAgenciesCardProps {
  groupAgencies: FranchisingItem | AssociationItem;
  hidden?: boolean;
}

export function GroupAgenciesCard({
  groupAgencies,
  hidden,
}: GroupAgenciesCardProps) {
  return (
    <PepitaMediaObject
      direction="colToRow"
      customClass={clsx(
        css['hp-groupAgenciesCard'],
        hidden && css['is-hidden']
      )}
    >
      <a href={groupAgencies.url} title={groupAgencies.title}>
        <PepitaFigure
          figureContent={groupAgencies.image}
          alt={groupAgencies.title}
          lazyLoad
          bordered
          ratio="custom"
        />
      </a>
      <PepitaMediaObjectContent>
        <PepitaMediaObjectTop>
          <PepitaMediaObjectTitle
            customClass={css['hp-groupAgenciesCard__title']}
          >
            <a href={groupAgencies.url}>{groupAgencies.title}</a>
          </PepitaMediaObjectTitle>
          {groupAgencies.text && (
            <p className={css['hp-groupAgenciesCard__contentText']}>
              {groupAgencies.text}
            </p>
          )}
        </PepitaMediaObjectTop>
      </PepitaMediaObjectContent>
    </PepitaMediaObject>
  );
}
