import type { FooterSegment, FooterSegmentCompact } from 'src/config/footer';
import { getProductConfig } from 'src/config/product';

const whoWeAreUrl = getProductConfig('whoWeAreUrl');
const leaderUrl = getProductConfig('leaderUrl');
const advertisingUrl = getProductConfig('advertisingUrl');
const contactUsUrl = getProductConfig('contactUsUrl');
const cityGuideUrl = getProductConfig('cityGuideUrl');
const workWithUsUrl = getProductConfig('workWithUsUrl');
const publishedAdsLandingUrl = getProductConfig('publishedAdsLandingUrl');
const antiScamUrl = getProductConfig('antiScamUrl');
const publishAdUrl = getProductConfig('publishAdUrl');
const newsUrl = getProductConfig('newsUrl');
const dossiersUrl = getProductConfig('dossiersUrl');
const magazineUrl = getProductConfig('magazineUrl');

const segments: FooterSegment[][] = [
  [
    {
      title: getProductConfig('brandName'),
      name: 'info',
      icon: 'info-circle',
      links: [
        ...(whoWeAreUrl
          ? [
              {
                label: 'ftr_anchor_who_we_are', // i18n-extract-keys ["ftr_anchor_who_we_are"]
                url: whoWeAreUrl,
                alternate: true,
              },
            ]
          : []),
        ...(leaderUrl
          ? [
              {
                label: 'lu_ftr_anchor_nr_1', // i18n-extract-keys ["lu_ftr_anchor_nr_1"]
                url: leaderUrl,
                alternate: true,
              },
            ]
          : []),
        ...(advertisingUrl
          ? [
              {
                label: 'ftr_anchor_advertising', // i18n-extract-keys ["ftr_anchor_advertising"]
                url: advertisingUrl,
                alternate: true,
              },
            ]
          : []),
        ...(contactUsUrl
          ? [
              {
                label: 'ftr_anchor_customer_care_contacts', // i18n-extract-keys ["ftr_anchor_customer_care_contacts"]
                url: contactUsUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_market_analysis', // i18n-extract-keys ["ftr_title_market_analysis"]
      name: 'dataAndAnalisys',
      icon: 'chart',
      links: [
        ...(cityGuideUrl
          ? [
              {
                label: 'ftr_anchor_house_prices', // i18n-extract-keys ["ftr_anchor_house_prices"]
                url: cityGuideUrl,
                alternate: true,
                isClientSideNavigation: true,
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_work_with_us', // i18n-extract-keys ["ftr_title_work_with_us"]
      name: 'workWithUs',
      icon: 'user-group',
      links: [
        ...(workWithUsUrl
          ? [
              {
                label: 'ftr_anchor_open_positions', // i18n-extract-keys ["ftr_anchor_open_positions"]
                url: workWithUsUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
  ],
  [
    {
      title: 'ftr_title_privates', // i18n-extract-keys ["ftr_title_privates"]
      name: 'privates',
      icon: 'user-round',
      links: [
        ...(contactUsUrl
          ? [
              {
                label: 'ftr_anchor_faq', // i18n-extract-keys ["ftr_anchor_faq"]
                url: contactUsUrl,
                alternate: true,
              },
            ]
          : []),
        ...(publishedAdsLandingUrl
          ? [
              {
                label: 'act_publish_a_ad', // i18n-extract-keys ["act_publish_a_ad"]
                url: publishedAdsLandingUrl,
                avoidLocalizedUrl: true,
                alternate: true,
                rel: 'nofollow',
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_safety', // i18n-extract-keys ["ftr_title_safety"]
      name: 'security',
      icon: 'shield',
      links: [
        ...(antiScamUrl
          ? [
              {
                label: 'ftr_anchor_anti_scams_advice', // i18n-extract-keys ["ftr_anchor_anti_scams_advice"]
                url: antiScamUrl,
                alternate: true,
                rel: 'nofollow',
              },
            ]
          : []),
      ],
    },
  ],
  [
    {
      title: 'ftr_title_agencies', // i18n-extract-keys ["ftr_title_agencies"]
      name: 'agencies',
      icon: 'suitcase',
      links: [
        ...(publishAdUrl
          ? [
              {
                label: 'ftr_anchor_publish_ad', // i18n-extract-keys ["ftr_anchor_publish_ad"]
                url: publishAdUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_real_estate_news', // i18n-extract-keys ["ftr_title_real_estate_news"]
      name: 'news',
      icon: 'newspaper',
      links: [
        ...(newsUrl
          ? [
              {
                label: 'ftr_anchor_blog_immotop', // i18n-extract-keys ["ftr_anchor_blog_immotop"]
                url: newsUrl,
                alternate: true,
              },
            ]
          : []),
        ...(dossiersUrl
          ? [
              {
                label: 'lu_dossiers_hero_headline', // i18n-extract-keys ["lu_dossiers_hero_headline"]
                url: dossiersUrl,
                alternate: true,
              },
            ]
          : []),
        ...(magazineUrl
          ? [
              {
                label: 'lbl_property_magazine', // i18n-extract-keys ["lbl_property_magazine"]
                url: magazineUrl,
                alternate: true,
                externalBaseUrl: true,
              },
            ]
          : []),
      ],
    },
  ],
];

const segmentsCompact: FooterSegmentCompact[][] = [
  [
    {
      title: getProductConfig('brandName'),
      name: 'info',
      links: [
        ...(whoWeAreUrl
          ? [
              {
                label: 'ftr_anchor_who_we_are', // i18n-extract-keys ["ftr_anchor_who_we_are"]
                url: whoWeAreUrl,
                alternate: true,
              },
            ]
          : []),
        ...(leaderUrl
          ? [
              {
                label: 'lu_ftr_anchor_nr_1', // i18n-extract-keys ["lu_ftr_anchor_nr_1"]
                url: leaderUrl,
                alternate: true,
              },
            ]
          : []),
        ...(workWithUsUrl
          ? [
              {
                label: 'ftr_anchor_open_positions', // i18n-extract-keys ["ftr_anchor_open_positions"]
                url: workWithUsUrl,
                alternate: true,
              },
            ]
          : []),
        ...(advertisingUrl
          ? [
              {
                label: 'ftr_anchor_advertising', // i18n-extract-keys ["ftr_anchor_advertising"]
                url: advertisingUrl,
                alternate: true,
              },
            ]
          : []),
        ...(contactUsUrl
          ? [
              {
                label: 'ftr_anchor_customer_care_contacts', // i18n-extract-keys ["ftr_anchor_customer_care_contacts"]
                url: contactUsUrl,
                alternate: true,
              },
            ]
          : []),
        ...(newsUrl
          ? [
              {
                label: 'lu_blog_real_estate',
                url: newsUrl,
                alternate: true,
              },
            ]
          : []),
        ...(publishAdUrl
          ? [
              {
                label: 'ftr_anchor_publish_ad', // i18n-extract-keys ["ftr_anchor_publish_ad"]
                url: publishAdUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
  ],
];

export default { segments, segmentsCompact };
