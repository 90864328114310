import { memo } from 'react';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';

import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';
import { homepageFormStateAtom } from '../atoms/homepageFormStateAtom';

import { RangeInput } from 'src/components/RangeInput';

import type { rangeConfigType } from '../config/formConfigTypes';

import type { FieldType } from '../types';

export const RangeField = memo(function RangeField({
  fieldConfig,
  customClass,
  selectedClass,
  onChange,
}: FieldType<rangeConfigType>) {
  const homepageFormKey = useAtomValue(homepageFormKeyAtom);
  const homepageFormState = useAtomValue(homepageFormStateAtom);

  const minValue =
    homepageFormState[homepageFormKey]?.[fieldConfig.fromName] ?? null;
  const maxValue =
    homepageFormState[homepageFormKey]?.[fieldConfig.toName] ?? null;

  const handleChange = (selection) => {
    onChange && onChange(fieldConfig.fromName, selection.min);
    onChange && onChange(fieldConfig.toName, selection.max);
  };

  const range = fieldConfig.range[0];

  const translationKeys = fieldConfig.translationKeys;

  return (
    <RangeInput
      customClass={clsx(
        customClass,
        (minValue !== null || maxValue !== null) && selectedClass
      )}
      fieldLabel={fieldConfig.label ?? undefined}
      min={range.min}
      max={range.max}
      minvalue={parseInt(String(minValue), 10)} // Forcing value to be a number
      maxvalue={parseInt(String(maxValue), 10)}
      step={range.step}
      translationKeys={translationKeys}
      onChange={handleChange}
      keepZeros={fieldConfig.keepZeros}
      shortLabelValues={fieldConfig.shortLabelValues}
    />
  );
});
