import { useCallback } from 'react';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { useTrack } from 'src/tracking/hooks/useTrack';
import { EventName } from 'src/tracking/utils/enums';
import { GATrackingEvent, SectionName } from 'src/tracking/utils/enums';

export const useHomepageTracking = () => {
  const track = useTrack();
  const sendGA4Event = useSendGA4Event();

  const trackBannerClick = useCallback(
    (entryPoint: 'Logo' | 'Banner') => {
      track(EventName.BANNER_SELECTED, {
        'Entry Point': entryPoint,
      });
    },
    [track]
  );

  const trackHomepageViewed = useCallback(() => {
    sendGA4Event(GATrackingEvent.HOMEPAGE_LOAD, {
      section_name: SectionName.HOMEPAGE,
    });
  }, [sendGA4Event]);

  return { trackBannerClick, trackHomepageViewed };
};
