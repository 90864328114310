import { clsx } from 'clsx';

import type { SeoMapItem, SeoSiteMapIndexesItem } from 'src/types/homepage';

import css from './styles.module.scss';

const isSeoMapItem = (
  item: SeoMapItem | SeoSiteMapIndexesItem
): item is SeoMapItem => {
  return (
    (item as SeoMapItem).nation !== undefined ||
    (item as SeoMapItem).region !== undefined
  );
};

export function ListMeta({
  list,
  isColumn,
  isLarge,
  customClass,
  withNation,
}: {
  list: SeoMapItem[] | SeoSiteMapIndexesItem[];
  isColumn?: boolean;
  isLarge?: boolean;
  customClass?: string;
  withNation?: boolean;
}) {
  return (
    <ul
      className={clsx(
        css['hp-listMeta'],
        isColumn && css['hp-listMeta--columns'],
        isLarge && css['hp-listMeta--large'],
        customClass
      )}
    >
      {list.map((item: SeoMapItem | SeoSiteMapIndexesItem, i: number) => {
        const isTitle = isSeoMapItem(item)
          ? item.nation || withNation || !item.link || item.region
          : withNation || !item.link;
        const isBold = isSeoMapItem(item)
          ? item.nation || withNation || item.region
          : withNation;

        return (
          <li
            className={clsx(
              css['hp-listMeta__item'],
              isTitle && css['hp-listMeta__item--title'],
              isBold && css['hp-listMeta__item--bold']
            )}
            key={i}
          >
            {Boolean(item.meta) && (
              <span className={css['hp-listMeta__meta']}>{item.meta}</span>
            )}
            {item.link ? (
              <a
                className={css['hp-listMeta__link']}
                href={item.link}
                // TODO opened issue #848 to improve SeoSiteMapIndexesItem type
                // @ts-ignore
                title={item.attr?.title}
              >
                {item.text}
              </a>
            ) : (
              item.text
            )}
            {item.items && item.items.length > 0 && (
              <ul className={css['hp-listMeta__group']}>
                {item.items.map((subItem, index) => {
                  return (
                    <li
                      className={clsx(
                        css['hp-listMeta__item'],
                        css['hp-listMeta__item--child']
                      )}
                      key={index}
                    >
                      {subItem.link ? (
                        <a
                          className={css['hp-listMeta__link']}
                          href={subItem.link}
                        >
                          {subItem.text}
                        </a>
                      ) : (
                        subItem.text
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
}
