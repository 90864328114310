import { useEffect, useRef } from 'react';

interface Options {
  enabled: boolean;
}

export const useFocusRef = <T extends HTMLElement>(
  initialValue: T | null,
  { enabled }: Options = { enabled: true }
) => {
  const ref = useRef<T>(initialValue);

  useEffect(() => {
    if (!enabled) return;

    setTimeout(() => {
      ref.current?.focus();
      // since we don't track any external state in this useEffect, we add an arbitrary timeout
    }, 100);
  }, [enabled]);

  return ref;
};
