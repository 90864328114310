import { memo, useMemo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';

import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';
import { homepageFormStateAtom } from '../atoms/homepageFormStateAtom';

import type { selectConfigType } from '../config/formConfigTypes';

import { PepitaSelect } from 'src/libs/ui/pepita-select';

import type { FieldType } from '../types';

import { capitalize } from 'src/utils/string';
export const SelectField = memo(function SelectField({
  fieldConfig,
  customClass,
  selectedClass,
  onChange,
}: FieldType<selectConfigType>) {
  const { trans } = useTranslations();

  const homepageFormKey = useAtomValue(homepageFormKeyAtom);
  const homepageFormState = useAtomValue(homepageFormStateAtom);

  // i18n-extract-disable-next-line
  const label = fieldConfig.label
    ? trans(fieldConfig.label, { capitalize: true })
    : '';

  const options = useMemo(() => {
    const items = fieldConfig.options.map((option) => ({
      // i18n-extract-disable-next-line
      label: trans(option.value),
      value: option.id,
    }));

    items.unshift({
      label: capitalize(trans('hp_form_select_whichever')),
      value: '',
    });

    return items;
  }, [fieldConfig.options, trans]);

  return (
    <PepitaSelect
      customClass={clsx(customClass, selectedClass)}
      title={label}
      options={options}
      shouldCapitalize={fieldConfig.capitalize}
      value={options.find(
        (option) =>
          option.value ===
          (homepageFormState[homepageFormKey]?.[fieldConfig.field] || '')
      )}
      /* i18n-extract-disable-next-line */
      placeholder={capitalize(trans(label))}
      onChange={(selection) => {
        onChange && onChange(fieldConfig.field, selection.value);
      }}
      confirmLabel={trans('act_confirm')}
    />
  );
});
