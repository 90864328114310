import { Fragment } from 'react';
import { clsx } from 'clsx';

import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';
import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

import css from './styles.module.scss';

export function LastThingsSkeleton({
  customClass,
  titleClass,
}: {
  customClass?: string;
  titleClass?: string;
}) {
  return (
    <>
      <div
        className={clsx(
          css['hp-lastThingSkeleton__head'],
          'nd-grid',
          titleClass
        )}
      >
        <PepitaSkeleton
          customClass={css['hp-lastThingSkeleton__title']}
          type="text"
          theme="light"
          items={1}
        />
      </div>
      <PepitaList
        customClass={clsx('nd-grid', css['hp-lastThingSkeleton'], customClass)}
        type="inline"
        items={Array.from(Array(4), (_, i) => (
          <Fragment key={i}>
            <SkeletonCard />
          </Fragment>
        ))}
      />
    </>
  );
}

function SkeletonCard() {
  return (
    <PepitaListItem customClass={css['hp-lastThingSkeleton__card']}>
      <PepitaSkeleton type="image" imageRatio="square" />
      <div className={css['hp-lastThingSkeleton__cardContent']}>
        <PepitaSkeleton type="text" items={2} />
        <PepitaSkeleton type="text" items={2} />
      </div>
    </PepitaListItem>
  );
}
