import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { isAgenciesProvinceEnabled } from 'src/components/RealEstateSearchMap/MapMarkers/utils';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import type { AgencyEssential, RawAgenciesExpert } from 'src/types/agency';
import type { Language } from 'src/types/translations';

import { http } from 'src/utils/client/http';
import { convertPolygonsPointsToString } from 'src/utils/geography';

// NOTE: just a reminder
// type QueryKey = readonly ['agencies', AgenciesByZoneParams];

const LATEST_STORAGE_KEY = 'imm_ultimaRicerca';
const RESULTS_LIMIT = 25;
const AGENCY_CATEGORY = 7;

type LastSearchType = {
  fkCategoria: string;
  fkComune?: string;
  fkProvincia?: string;
  fkRegione?: string;
  fkMacrozonaV2?: string;
  fkMicrozona?: string;
  poligono?: { mode: string; area: any };
  fkMetro?: string;
  fkMetroStation?: string;
  indirizzo?: string;
  url: string;
};

type AgenciesExpertLastSearch = {
  agencies: Nullable<AgencyEssential[]>;
  title: {
    label: Nullable<string>;
    url: Nullable<string>;
  };
};

export const useAgenciesExpertLastSearch = (): AgenciesExpertLastSearch => {
  const { trans } = useTranslations();
  const { locale } = useRouter() as { locale: Language };

  const product = getProductConfig('name');

  const [lastSearch, setLastSearch] = useState<LastSearchType | null>(null);

  useEffect(() => {
    const storageData = localStorage.getItem(LATEST_STORAGE_KEY);

    setLastSearch(storageData ? JSON.parse(storageData) : null);
  }, []);

  const isAgenciesExpertsSortByBundle = isFeatureEnabled(
    'AGENCIES_EXPERT_SORT_BY_BUNDLE'
  );

  const isSearchByCircle = lastSearch?.poligono?.mode === 'circle';
  const isSearchByCustomPolygon = lastSearch?.poligono?.mode === 'polygon';
  const isSearchByPolygonOrRadius = isSearchByCircle || isSearchByCustomPolygon;

  // immotop also shows results for searches by province
  const isAgenciesExpertHomeEnabled =
    isFeatureEnabled('AGENCIES_EXPERT_HOME') &&
    isAgenciesProvinceEnabled(lastSearch?.fkComune, lastSearch?.fkProvincia);

  const isAgenciesExpertByPolygonOrAreaEnabled =
    isFeatureEnabled('AGENCIES_EXPERT_BY_DISTANCE_OR_AREA_SEARCH') &&
    isSearchByPolygonOrRadius;

  const isEnabled =
    isAgenciesExpertHomeEnabled || isAgenciesExpertByPolygonOrAreaEnabled;

  const limit = isAgenciesExpertsSortByBundle
    ? getProductConfig('agenciesExpertResultsLimit')
    : RESULTS_LIMIT;

  const searchParams = {
    'city-id': lastSearch?.fkComune,
    'province-id': lastSearch?.fkProvincia,
    'macrozone-id':
      lastSearch?.fkMacrozonaV2 && typeof lastSearch.fkMacrozonaV2 === 'string'
        ? lastSearch.fkMacrozonaV2.split(',')
        : null,
    'microzone-id':
      lastSearch?.fkMicrozona && typeof lastSearch.fkMicrozona === 'string'
        ? lastSearch.fkMicrozona.split(',')
        : null,
    banner: product === 'immotop-lu' ? 1 : null, // necessary parameter to the ws to determine if the data are for immotop
    'category-id': isAgenciesExpertsSortByBundle ? AGENCY_CATEGORY : null,
    'sort-by': isAgenciesExpertsSortByBundle ? 'bundle' : null,
    limit,
    output: 'json',
    ...(isSearchByCircle
      ? {
          radius: lastSearch.poligono?.area.radius,
          center: lastSearch.poligono?.area.center,
        }
      : {
          points: lastSearch?.poligono?.area
            ? convertPolygonsPointsToString(lastSearch.poligono.area)
            : null,
        }),
  };

  const { isFetching, data } = useQuery<RawAgenciesExpert>({
    queryKey: ['agencies', searchParams, locale],
    queryFn: () =>
      http
        .get('/api-next/agencies/local-expert/', {
          searchParams: { ...searchParams, __lang: locale },
        })
        .json<RawAgenciesExpert>(),
    enabled: isEnabled,
  });

  const getUrl = () => {
    return data?.searchAgencyUrl &&
      isFeatureEnabled('AGENCIES_EXPERT_ZONE_TITLE_ENABLED')
      ? data.searchAgencyUrl
      : null;
  };

  const getLabel = () => {
    return product === 'immotop-lu'
      ? trans('area_agencies_featured')
      : trans('area_agencies');
  };

  /**
   * Solve the problem of CLS described below
   * MR:https://gitlab.pepita.io/indomio/indomita/website-next/merge_requests/2595#note_430891
   * */

  return !isFetching
    ? {
        agencies: data && data.agencies.length > 0 ? data.agencies : null,
        title: {
          label: getLabel(),
          url: getUrl(),
        },
      }
    : {
        agencies: [],
        title: {
          label: null,
          url: null,
        },
      };
};
