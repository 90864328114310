import type { PropsWithChildren } from 'react';
import { Children, cloneElement } from 'react';
import { clsx } from 'clsx';

import css from './index.module.scss';

export type FeaturesProps = {
  customClass?: string;
  size?: 'compact' | 'full';
  hasMain?: boolean;
  isMainProperty?: boolean;
};

export function Features({
  children,
  customClass,
  size,
  hasMain,
  isMainProperty,
}: PropsWithChildren<FeaturesProps>) {
  return (
    <ul
      className={clsx(
        'nd-list',
        'nd-list--pipe',
        css['in-feat'],
        {
          [css[`in-feat--${size}`]]: size,
        },
        {
          [css['in-feat__mainProperty']]: isMainProperty,
        },
        customClass
      )}
    >
      {Children.map(children, (child, i) => {
        return (
          child &&
          cloneElement(child as any, {
            size,
            isMain: hasMain && i === 0,
          })
        );
      })}
    </ul>
  );
}

Features.displayName = 'Features';

export { FeatureItem } from './FeatureItem';
export { MainFeatures } from './MainFeatures';
