import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';

import type { FormStateType } from 'src/components/SearchFilters/types';

import type { GeographySearch } from 'src/libs/geography';

import { useTrack } from 'src/tracking/hooks/useTrack';
import {
  CONTRACT_MAPPING,
  formatGeographyParams,
  getSearchType,
} from 'src/tracking/utils';
import { EventName } from 'src/tracking/utils/enums';

import type { GeographySearchParams, SearchParams } from 'src/types/search';
import type { HOMEPAGE_FORM_KEYS } from '../types';

export const useSearchFromHomepageTracking = () => {
  const track = useTrack();
  const { user } = useAuthContext();

  const trackSearchFromHomepage = useCallback(
    (data: {
      geographyData: GeographySearch | null;
      geographyParams: GeographySearchParams | SearchParams;
      homepageFormState?: FormStateType;
      homepageFormKey: HOMEPAGE_FORM_KEYS;
    }) => {
      const { geographyData, geographyParams, homepageFormState } = data;

      // in this way we're preventing to check if homepageFormState is defined on every single line
      const homePageFormStateCheck = homepageFormState ?? {};

      track(EventName.SEARCH_FROM_HOME, {
        'Type of Search': getSearchType(geographyData),
        'Category Filter': homePageFormStateCheck.idCategoria
          ? homePageFormStateCheck.idCategoria
          : undefined,
        'Contract Filter': homePageFormStateCheck.idContratto
          ? CONTRACT_MAPPING[homePageFormStateCheck.idContratto]
          : undefined,
        'Type Filter': homePageFormStateCheck.idTipologia?.length
          ? homePageFormStateCheck.idTipologia
          : undefined,
        'Max Price Filter': homePageFormStateCheck.prezzoMassimo,
        'Max Surface Filter': homePageFormStateCheck.superficieMassima,
        'Min Price Filter': homePageFormStateCheck.prezzoMinimo,
        'Min Surface Filter': homePageFormStateCheck.superficieMinima,
        'Is User Logged': Boolean(user),
        ...formatGeographyParams({
          searchParams: geographyParams,
          geographyData,
        }),
      });
    },
    [user, track]
  );

  return { trackSearchFromHomepage };
};
