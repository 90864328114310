export function SvgText({
  headline,
  customClass,
  onClickText,
}: {
  headline: string;
  customClass: string;
  onClickText?: () => void;
}) {
  // rif. https://gitlab.pepita.io/snippets/261
  const fontSize = 12.7;
  const acw = 7.48; // average character width
  const charConst = fontSize / acw;
  const viewBoxWidth = Math.round(headline.length * (fontSize / charConst));

  return (
    <svg
      className={customClass}
      onClick={onClickText ? () => onClickText() : undefined}
      viewBox={`0 0 ${Math.min(240, viewBoxWidth)} 20`}
    >
      <text
        x="50%"
        y="50%"
        fontSize={fontSize}
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="central"
      >
        {headline}
      </text>
    </svg>
  );
}
