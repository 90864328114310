import type { ReactNode } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';

import { getProductConfig } from 'src/config/product';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';
import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL } from 'src/utils/url';

import { FooterStyles } from './index';

const footerBrandName = getProductConfig('brandName');
const footerPartitaIva = getProductConfig('partitaIva');
const footerSocialConf = getProductConfig('socials');
const footerTermsConf = getProductConfig('terms');

export type FooterBottomProps = {
  active?: boolean;
  customClass?: string;
  socials?: boolean;
  disclaimer?: boolean;
};

export function FooterBottom({
  active,
  customClass,
  socials,
  disclaimer,
}: FooterBottomProps) {
  const { trans } = useTranslations();
  const { locale, defaultLocale } = useRouter();

  const disclaimerItems: ReactNode[] = [
    `© ${new Date().getFullYear()} ${footerBrandName} | `,
  ];

  if (footerPartitaIva) {
    disclaimerItems.push(`P. IVA ${footerPartitaIva} |`);
  }

  if (footerTermsConf) {
    disclaimerItems.push(
      <>
        {footerTermsConf.conditionUrl && (
          <a
            href={alternateAbsoluteURL(
              footerTermsConf.conditionUrl,
              defaultLocale as Language,
              locale as Language
            )}
            rel="nofollow"
          >
            {trans('general_conditions')}
          </a>
        )}
        {footerTermsConf.privacyUrl && (
          <>
            {footerTermsConf.conditionUrl && ', '}
            <a
              href={alternateAbsoluteURL(
                footerTermsConf.privacyUrl,
                defaultLocale as Language,
                locale as Language
              )}
              rel="nofollow"
            >
              {trans('privacy_rules')}
            </a>
            {` ${trans('lbl_and')}`}
            {footerTermsConf.cookiesUrl && ' '}
          </>
        )}
        {footerTermsConf.cookiesUrl && (
          <a
            href={alternateAbsoluteURL(
              footerTermsConf.cookiesUrl,
              defaultLocale as Language,
              locale as Language
            )}
            rel="nofollow"
          >
            {trans('cookie_usage')}
          </a>
        )}
      </>
    );
  }

  if (!active) return null;

  return (
    <div className={clsx(FooterStyles.bottom, customClass && customClass)}>
      <div className={FooterStyles.inner}>
        {disclaimer && (
          <div className={FooterStyles.section}>
            <PepitaList
              customClass={FooterStyles.disclaimer}
              type="inline"
              items={disclaimerItems.map((item, i) => (
                <PepitaListItem key={i}>{item}</PepitaListItem>
              ))}
            />
          </div>
        )}
        {socials && footerSocialConf && (
          <div
            className={clsx(FooterStyles.section, FooterStyles.sectionSocial)}
          >
            <div className={FooterStyles.social}>
              {footerSocialConf.facebookUrl && (
                <a
                  href={footerSocialConf.facebookUrl}
                  className={FooterStyles.socialIcon}
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <PepitaIcon name="facebook" />
                </a>
              )}
              {footerSocialConf.instagramUrl && (
                <a
                  href={footerSocialConf.instagramUrl}
                  className={FooterStyles.socialIcon}
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <PepitaIcon name="instagram" />
                </a>
              )}
              {footerSocialConf.twitterUrl && (
                <a
                  href={footerSocialConf.twitterUrl}
                  className={FooterStyles.socialIcon}
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <PepitaIcon name="x" />
                </a>
              )}
              {footerSocialConf.linkedinUrl && (
                <a
                  href={footerSocialConf.linkedinUrl}
                  className={FooterStyles.socialIcon}
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <PepitaIcon name="linkedin" />
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
