import { useIsOnClient } from '@indomita-react/custom-hooks';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

import { NumberOneLink, NumberOneLinkSkeleton } from '../NumberOneLink';

import css from './styles.module.scss';

export function MobileButtons() {
  const { trans } = useTranslations();
  const isOnClient = useIsOnClient();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const publishedAdsLandingUrl = getProductConfig('publishedAdsLandingUrl');

  // Adds skeleton on mobile during load to avoid CLS
  if (!isOnClient) {
    return (
      <>
        {isFeatureEnabled('PUBLISH_MOBILE_BTN') && (
          <div className={css['hp-mobileButtons']}>
            <PepitaSkeleton
              type="image"
              customClass={css['hp-mobileButtons__skeleton']}
            />
          </div>
        )}
        {isFeatureEnabled('NUMBER_ONE_LINK') && (
          <div className={css['hp-mobileButtons']}>
            <NumberOneLinkSkeleton />
          </div>
        )}
      </>
    );
  } else if (maxScreenWidthSm) {
    return (
      <>
        {isFeatureEnabled('PUBLISH_MOBILE_BTN') && (
          <div className={css['hp-mobileButtons']}>
            <PepitaButton
              as="a"
              customClass={css['hp-mobileButtons__publishButton']}
              block
              href={`${publishedAdsLandingUrl}?from=homeMobile`}
            >
              {trans('act_publish_free_ad')}
            </PepitaButton>
          </div>
        )}
        {isFeatureEnabled('NUMBER_ONE_LINK') && (
          <div className={css['hp-mobileButtons']}>
            <NumberOneLink />
          </div>
        )}
      </>
    );
  }
}
