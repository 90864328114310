import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { isFeatureEnabled } from 'src/config/features-toggle';

import type { Article } from 'src/types/dossiers';

import { http } from 'src/utils/client/http';

export const useLatestDossier = (limit: number) => {
  const { locale } = useRouter();
  const { data, isFetching } = useQuery<Article[]>({
    queryKey: ['dossiers', locale, limit],
    queryFn: () =>
      http
        .get(`/api-next/dossiers/?__lang=${locale}&limit=${limit}`)
        .json<Article[]>(),
    enabled: isFeatureEnabled('DOSSIERS_ENABLED'),
  });

  return { data, isFetching };
};
