import type {
  AtomicStateAtomRef,
  AtomicStateStateAtom,
} from 'src/atoms/atomic-state';

import type { fieldConfigType, formConfigType } from './config/formConfigTypes';

import type { CONTRACT } from 'src/constants/typologies';

export enum HOMEPAGE_FORM_KEYS {
  SALE = 'sale',
  RENT = 'rent',
  AUCTION = 'auction',
  APPRAISE = 'appraise',
  SELLER = 'seller',
  AGENCIES = 'agencies',
  INTERNATIONAL_SALE = 'internationalSale',
  INTERNATIONAL_RENT = 'internationalRent',
  HOLIDAY = 'holiday',
  MYHOME = 'myHome',
}

export type HomepageForms = {
  [HOMEPAGE_FORM_KEYS.SALE]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.RENT]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.AUCTION]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.APPRAISE]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.SELLER]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.AGENCIES]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.INTERNATIONAL_SALE]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.INTERNATIONAL_RENT]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.HOLIDAY]?: HomepageForm;
  [HOMEPAGE_FORM_KEYS.MYHOME]?: HomepageForm;
};

export type HomepageForm = {
  text: string;
  inputs: formConfigType;
  contractId?: CONTRACT;
  isNew?: boolean;
};

export type formStateRefType = AtomicStateAtomRef<
  AtomicStateStateAtom<FormStateType>
>;

export type formStateRefKey = AtomicStateAtomRef<
  AtomicStateStateAtom<HOMEPAGE_FORM_KEYS>
>;

export type FormStateType = {
  [key in HOMEPAGE_FORM_KEYS]?: any;
};

export type FieldType<C = fieldConfigType> = {
  fieldConfig: C;
  customClass?: string;
  controlCustomClass?: string;
  selectedClass?: string;
  onChange?: (label: string, value: string | string[] | number | null) => void;
};
