import type { ReactNode } from 'react';
import featureSprite from '@pepita-fe/sprite-main-features/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';

import { FeatureItem } from 'src/components/Features';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';

import type { PropertyPortion } from 'src/types/real-estate';
import { WorkstationType } from 'src/types/real-estate';

import type { Trans } from 'src/utils/i18nFormatters';

import css from './index.module.scss';

enum PortionsIcons {
  PRIVATE_OFFICE_TYPE = 'door',
  MEETINGS_TYPE = 'whiteboard',
  FIXED_WORKSTATION_TYPE = 'desk',
  MOBILE_WORKSTATION_TYPE = 'office-chair',
}

type MainFeaturesProps = {
  propertiesCount?: number;
  property: {
    [key: string]: any;
    elevator?: boolean;
  };
  from?: boolean;
  size?: 'compact' | 'full';
  isMain?: boolean;
  isLuxury?: boolean;
  showElevator?: boolean;
};

type MainFeature = {
  amount?: string | number;
  icon?: PepitaIconProps;
  label: string;
  content?: ReactNode;
  customClass?: string;
  active?: boolean;
  keepZeros?: boolean;
};

function isNonEmptyValue(value?: string | number, keepZeros?: boolean) {
  const numValue = Number(value);

  if (isNaN(numValue)) return false;

  return numValue > 0 || (keepZeros && numValue === 0);
}

function isNotEmptyFeature(value: MainFeature) {
  return value.active || isNonEmptyValue(value.amount, value.keepZeros);
}

export const getPortionsIcon = (
  workStationType: WorkstationType
): Optional<PepitaIconProps> => {
  switch (workStationType) {
    case WorkstationType.FIXED_WORKSTATION:
      return {
        spriteUrl: featureSprite,
        name: PortionsIcons.FIXED_WORKSTATION_TYPE,
      };
    case WorkstationType.MEETINGS:
      return {
        spriteUrl: featureSprite,
        name: PortionsIcons.MEETINGS_TYPE,
      };
    case WorkstationType.MOBILE_WORKSTATION:
      return {
        spriteUrl: featureSprite,
        name: PortionsIcons.MOBILE_WORKSTATION_TYPE,
      };
    case WorkstationType.PRIVATE_OFFICE:
      return {
        spriteUrl: featureSprite,
        name: PortionsIcons.PRIVATE_OFFICE_TYPE,
      };
    default:
      return undefined;
  }
};

export const getElevatorValue = (
  elevator: boolean | undefined,
  trans: Trans
) => {
  if (elevator === undefined) {
    return undefined;
  }

  return trans(elevator ? 'lbl_yes' : 'lbl_not');
};

export function MainFeatures({
  propertiesCount,
  property,
  from,
  size,
  isMain,
  isLuxury,
  showElevator,
}: MainFeaturesProps) {
  const { trans } = useTranslations();

  let features: MainFeature[] = [];

  const surface = property.surface?.split ? property.surface.split(' ') : null;

  // For product needs, in auctions case, we count the number of lots as types
  let typologyAmount = propertiesCount;

  if (property.auction && property.auction.totalLotEstates > 1) {
    typologyAmount = property.auction.totalLotEstates;
  }

  if (property.portions) {
    features = property.portions
      .filter((portion: PropertyPortion) =>
        isNonEmptyValue(portion.workstation.amount)
      )
      .map((portion: PropertyPortion) => {
        const amount = portion.workstation.amount;

        return {
          amount,
          icon: getPortionsIcon(portion.workstation.type),
          label: portion.workstation.amountLabel,
        };
      });
  } else {
    features = [
      {
        amount: property.shop?.wall,
        icon: {
          name: 'wall',
          spriteUrl: featureSprite,
        },
        label: trans('lbl_wall'),
      },
      {
        amount: property.income && trans('lbl_yes'),
        icon: {
          name: 'euro-circle',
        },
        label: trans('lbl_income'),
      },
      // Todo: chiedere ad Alberto se c'è il pericolo che abbiamo
      // workstation.capacityRange per casi che no nsiano quelli della condizione
      {
        amount:
          (property.workstation?.type === 'private-office' ||
            property.workstation?.type === 'meetings') &&
          property.workstation?.capacityRange,
        icon: {
          name: 'person',
        },
        label: trans('lbl_workstation', { count: 1 }),
      },
      {
        amount: property.rooms,
        icon: {
          name: 'planimetry',
          spriteUrl: featureSprite,
        },
        label: trans('lbl_room', { count: property.rooms }),
      },
      {
        amount: property.auction?.saleDateValue,
        icon: {
          name: property.auction?.highlightSaleDate ? 'clock' : 'calendar',
          spriteUrl: featureSprite,
          customClass:
            property.auction?.highlightSaleDate &&
            css['in-mainFeatures__date--evidence'],
        },

        label: trans('lbl_sale_date'),
      },
      {
        amount: surface && surface[0],
        label: trans('lbl_measurement'),
        icon: {
          name: 'size',
          spriteUrl: featureSprite,
        },
        content: surface && (
          <SurfaceContent
            from={Boolean(from)}
            amount={surface[0]}
            pointer={surface[1]}
          />
        ),
      },
      {
        amount: property.bathrooms,
        icon: {
          name: 'bath',
          spriteUrl: featureSprite,
        },
        label: trans('lbl_bathroom', { count: property.bathrooms }),
      },

      {
        amount: property.floor?.abbreviation,
        icon: {
          name: 'stairs',
          spriteUrl: featureSprite,
        },
        label: trans('lbl_floor'),
        // in this case amount can be "0"
        // workaround to pass the check of isNonEmptyValue
        keepZeros: true,
      },
      {
        amount: showElevator
          ? getElevatorValue(property.elevator, trans)
          : undefined,
        icon: {
          name: 'elevator',
          spriteUrl: featureSprite,
        },
        label: trans('lbl_elevator').toLowerCase(),
      },
      {
        amount: typologyAmount,
        icon: {
          name: 'home',
        },
        label: trans('lbl_typology', { count: 2 }),
      },
      {
        icon: {
          name: 'diamond',
        },
        label: trans('lbl_luxury').toLowerCase(),
        amount: undefined,
        active: isLuxury,
        content: size && size === 'compact' ? trans('lbl_luxury') : null,
      },
    ];
  }

  return (
    <>
      {features.filter(isNotEmptyFeature).map((item, i) => (
        <FeatureItem
          key={i}
          isMain={isMain}
          icon={item.icon}
          label={item.label}
          customClass={item.customClass}
          content={item.content || item.amount}
        />
      ))}
    </>
  );
}

function SurfaceContent(props: {
  amount: string;
  pointer: string;
  from: boolean;
}) {
  const { trans } = useTranslations();

  return (
    <>
      {props.from && (
        <span className={css['in-mainFeatures__text']}>
          {trans('lbl_from')}
        </span>
      )}
      {props.amount}
      {props.pointer && (
        <span className={css['in-mainFeatures__text']}>{props.pointer}</span>
      )}
    </>
  );
}
