import { clsx } from 'clsx';

import type { PepitaListItemProps } from 'src/libs/ui/pepita-list';
import { PepitaListItem } from 'src/libs/ui/pepita-list';

import { ContentItemList } from '../ContentItemList';

import css from './styles.module.scss';

export type FooterTopListItemProps = {
  link?: {
    rel?: string;
    title?: string;
  };
  isClientOnlyLink?: boolean;
  isClientSideNavigation?: boolean;
} & Pick<PepitaListItemProps, 'link' | 'text'>;

export function FooterTopListItem({
  text,
  link,
  isClientOnlyLink,
  isClientSideNavigation,
}: FooterTopListItemProps) {
  const handleLinkClick = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();
    if (link) {
      window.open(link.href, link.target || '_self');
    }
  };

  return (
    <PepitaListItem
      onClick={isClientOnlyLink ? (e) => handleLinkClick(e) : undefined}
      customClass={
        isClientOnlyLink
          ? clsx(
              FooterTopStyles.listLink,
              css[FooterTopStyles.listLinkInteractive]
            )
          : undefined
      }
      title={isClientOnlyLink && link?.title ? link.title : undefined}
    >
      <ContentItemList
        link={!isClientOnlyLink ? link : undefined}
        text={text}
        handleLinkClick={handleLinkClick}
        isClientSideNavigation={isClientSideNavigation}
      />
    </PepitaListItem>
  );
}

enum FooterTopStyles {
  listLink = 'nd-list__link',
  listLinkInteractive = 'in-footerTopListItem--interactive',
}
