import { clsx } from 'clsx';

import { RelatedLinks } from 'src/components/RelatedLinks';

import type { SeoSiteMap as SeoSiteMapType } from 'src/types/homepage';

import { ListMeta } from '../ListMeta';

import css from './styles.module.scss';

export function SeoSiteMap({ seoSiteMap }: { seoSiteMap: SeoSiteMapType }) {
  return (
    <section
      className={clsx('nd-grid', css['hp-seoSiteMap'])}
      data-cy="site-map"
    >
      <h1 className={css['hp-seoSiteMap__title']}>{seoSiteMap.title}</h1>
      <p
        className={css['hp-seoSiteMap__description']}
        dangerouslySetInnerHTML={{ __html: seoSiteMap.description }}
      />
      {Object.keys(seoSiteMap.indexes).map((char, i) => {
        return (
          <section key={i} className={css['hp-seoSiteMap__block']}>
            <p className={css['hp-seoSiteMap__index']}>
              <span className={css['hp-seoSiteMap__indexValue']}>{char}</span>
            </p>
            <ListMeta
              list={seoSiteMap.indexes[char].items}
              isLarge
              customClass={css['hp-seoSiteMap__list']}
            />
          </section>
        );
      })}

      {seoSiteMap.related && (
        <RelatedLinks
          title={seoSiteMap.related.title}
          TitleTag="span"
          config={[
            {
              data: seoSiteMap.related.items,
            },
          ]}
          customClass={css['hp-seoSiteMap__related']}
        />
      )}
    </section>
  );
}
