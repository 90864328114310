import { useState } from 'react';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';

import { VERTICALS } from 'src/constants/typologies';

import type { PageContent } from 'src/types/homepage';
import type { SeoMap as SeoMapType } from 'src/types/homepage';

import { GroupAgenciesCard } from '../GroupAgenciesCard';
import { ListMeta } from '../ListMeta';
import { PanelMeta } from '../PanelMeta';

import css from './styles.module.scss';

export function SeoMap({
  seoMap,
  pageContent,
  customClass,
}: {
  seoMap: SeoMapType;
  pageContent: PageContent;
  customClass?: string;
}) {
  const router = useRouter();
  const agencyPage = VERTICALS.AGENZIA === `${pageContent.categoryId}`;

  const [openPanel, setOpenPanel] = useState<'cities' | 'associations'>(
    'cities'
  );

  const handlePanel = (e) => {
    e.preventDefault();
    router.push(e.target.href);
    const target = e.target.dataset.target;

    setOpenPanel(target);
  };

  return (
    <section
      className={clsx(css['hp-seoMap'], customClass)}
      data-cy="seo-map"
      id="map-list"
    >
      <h1
        className={clsx(
          css['hp-seoMap__title'],
          'nd-typography--headingLarge',
          'nd-grid'
        )}
      >
        {seoMap.title}
      </h1>
      <p className={clsx(css['hp-seoMap__description'], 'nd-grid')}>
        {seoMap.description}
      </p>
      {/* tab */}
      <div className="nd-grid">
        {seoMap.rows.map((tabs, tabsIndex) => {
          return (
            <ul
              key={tabsIndex}
              className={clsx(
                'nd-tabBar',
                tabsIndex !== 0 && 'nd-tabBar--compact',
                css['hp-seoMap__tabBar']
              )}
            >
              {tabs.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      className={clsx(
                        'nd-tab',
                        (item.attr['data-target']
                          ? openPanel === item.attr['data-target']
                          : item.active) && 'nd-tab--active'
                      )}
                      href={item.link}
                      title={item.attr.title}
                      onClick={
                        item.attr['data-target']
                          ? (e) => handlePanel(e)
                          : undefined
                      }
                      data-target={item.attr['data-target']}
                    >
                      {item.text}
                    </a>
                  </li>
                );
              })}
            </ul>
          );
        })}
      </div>
      {/* panel list */}
      <div
        data-target={agencyPage ? 'cities' : null}
        className={clsx(
          'nd-grid',
          css['hp-seoMap__panel'],
          ((agencyPage && openPanel === 'cities') || !agencyPage) &&
            css['hp-seoMap__panel--active']
        )}
      >
        {seoMap.panels
          ? seoMap.panels.map((panel, index) => (
              <PanelMeta key={index} panel={panel} />
            ))
          : seoMap.items && <ListMeta list={seoMap.items} isColumn />}
      </div>
      {/* panel associations */}
      {seoMap.associations && agencyPage && (
        <div
          data-target={'associations'}
          className={clsx(
            'nd-grid',
            css['hp-seoMap__panel'],
            openPanel === 'associations' && css['hp-seoMap__panel--active']
          )}
        >
          {seoMap.associations.items.map((item, i) => {
            return <GroupAgenciesCard key={i} groupAgencies={item} />;
          })}
        </div>
      )}
    </section>
  );
}
