import { getProductConfig } from 'src/config/product';

import type { Language } from 'src/types/translations';

import { getPathFromUrl } from 'src/utils/querystring';
import { alternateAbsoluteURL } from 'src/utils/url';

const defaultSearchPrefix = getProductConfig('defaultSearchPrefix');

export const getUrlPrefix = (
  asPath: string,
  defaultLocale: Language,
  locale: Language
) => {
  const path = getPathFromUrl(asPath);

  const url =
    path === '/'
      ? defaultSearchPrefix
      : path.slice(-path.length, path.lastIndexOf('/'));

  return alternateAbsoluteURL(
    url,
    defaultLocale as Language,
    locale as Language
  );
};
