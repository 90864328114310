import type { GeoJSON } from '@indomita-website/search/types';

import type { BaseEntityWithChildren } from 'src/components/GeographySearch/types';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { CATEGORIES, CONTRACT, VERTICALS } from 'src/constants/typologies';

import type { PageContent, PageLookup } from 'src/types/homepage';
import type { HomepageForm, HomepageForms } from '../types';
import { HOMEPAGE_FORM_KEYS } from '../types';

import { nonNullable } from 'src/utils/common';

import { FormFields } from './formConfigTypes';
import {
  getAgenciesLocationInput,
  getAgenciesSearchInput,
  getAgencyGroupsInput,
  getAssociationsInput,
  getAuctionsLocationInput,
  getAutocompleteInput,
  getCategoryInput,
  getCategoryInternationalInput,
  getCategoryResidentialInput,
  getLocationInput,
  getPriceInput,
  getSurfaceInput,
} from './inputsConfig';

export const getHomepageFormKey = (
  pageContent: PageContent
): Nullable<HOMEPAGE_FORM_KEYS> => {
  if (
    VERTICALS.RICERCA_INTERNAZIONALE === `${pageContent.categoryId}` &&
    CONTRACT.VENDITA === `${pageContent.contractId}`
  ) {
    return HOMEPAGE_FORM_KEYS.INTERNATIONAL_SALE;
  }

  if (
    VERTICALS.RICERCA_INTERNAZIONALE === `${pageContent.categoryId}` &&
    CONTRACT.AFFITTO === `${pageContent.contractId}`
  ) {
    return HOMEPAGE_FORM_KEYS.INTERNATIONAL_RENT;
  }

  if (VERTICALS.TURISTICO === `${pageContent.categoryId}`) {
    return HOMEPAGE_FORM_KEYS.HOLIDAY;
  }

  if (
    CONTRACT.VENDITA === `${pageContent.contractId}` &&
    CATEGORIES.ASTE !== `${pageContent.categoryId}` &&
    VERTICALS.AGENZIA !== `${pageContent.categoryId}`
  ) {
    return HOMEPAGE_FORM_KEYS.SALE;
  }

  if (
    CONTRACT.AFFITTO === `${pageContent.contractId}` &&
    CATEGORIES.ASTE !== `${pageContent.categoryId}` &&
    VERTICALS.AGENZIA !== `${pageContent.categoryId}`
  ) {
    return HOMEPAGE_FORM_KEYS.RENT;
  }

  if (CATEGORIES.ASTE === `${pageContent.categoryId}`) {
    return HOMEPAGE_FORM_KEYS.AUCTION;
  }

  if (VERTICALS.AGENZIA === `${pageContent.categoryId}`) {
    return HOMEPAGE_FORM_KEYS.AGENCIES;
  }

  return null;
};

export const getHomepageForms = (
  pageContent: PageContent,
  pageLookup: PageLookup,
  onAutoSubmit: (values: BaseEntityWithChildren[] | GeoJSON) => void
): HomepageForms => {
  const isAlternate = pageContent.isAlternate;

  const isInternational =
    VERTICALS.RICERCA_INTERNAZIONALE === `${pageContent.categoryId}`;
  const isHoliday = VERTICALS.TURISTICO === `${pageContent.categoryId}`;

  /**
   * In case of international or holiday verticals, we override other form tabs
   */
  if (isInternational) {
    return {
      [HOMEPAGE_FORM_KEYS.INTERNATIONAL_SALE]: getInternationalForm(
        CONTRACT.VENDITA,
        onAutoSubmit
      ),
      [HOMEPAGE_FORM_KEYS.INTERNATIONAL_RENT]: getInternationalForm(
        CONTRACT.AFFITTO,
        onAutoSubmit
      ),
    };
  }

  if (isHoliday) {
    return {
      [HOMEPAGE_FORM_KEYS.HOLIDAY]: getHolidayForm(),
    };
  }

  return {
    sale: getSaleForm(isAlternate, onAutoSubmit),
    rent: getRentForm(isAlternate, onAutoSubmit),
    [HOMEPAGE_FORM_KEYS.SELLER]:
      isFeatureEnabled('SELLER_LEAD') && !isAlternate
        ? sellerLeadForm
        : undefined,
    [HOMEPAGE_FORM_KEYS.APPRAISE]:
      isFeatureEnabled('EVALUATE_BUILDING') && !isAlternate
        ? getAppraiseForm(isAlternate)
        : undefined,
    [HOMEPAGE_FORM_KEYS.MYHOME]:
      isFeatureEnabled('SEARCH_TAB_MY_HOME') && !isAlternate
        ? myHomeForm
        : undefined,
    [HOMEPAGE_FORM_KEYS.AUCTION]: isFeatureEnabled('AUCTIONS_ENABLED')
      ? getAuctionForm(isAlternate, onAutoSubmit)
      : undefined,
    [HOMEPAGE_FORM_KEYS.AGENCIES]: isFeatureEnabled(
      'AGENCIES_HOMEPAGE_SEARCH_ENABLED'
    )
      ? getAgenciesForm(pageLookup)
      : undefined,
  };
};

const getSaleForm = (
  isAlternate: boolean,
  onAutoSubmit: (values: BaseEntityWithChildren[] | GeoJSON) => void
): HomepageForm => {
  const contractId = CONTRACT.VENDITA;

  return {
    text:
      isFeatureEnabled('EVALUATE_BUILDING') && !isAlternate
        ? 'lbl_buy'
        : 'lbl_for_sale', // i18n-extract-keys ["lbl_for_sale", "lbl_buy"]
    inputs: [
      getLocationInput(onAutoSubmit),
      getCategoryInput(),
      getPriceInput(contractId),
      getSurfaceInput(),
    ],
    contractId,
  };
};

const getRentForm = (
  isAlternate: boolean,
  onAutoSubmit: (values: BaseEntityWithChildren[] | GeoJSON) => void
): HomepageForm => {
  const contractId = CONTRACT.AFFITTO;

  return {
    text:
      isFeatureEnabled('EVALUATE_BUILDING') && !isAlternate
        ? 'lbl_rent'
        : 'lbl_for_rent', // i18n-extract-keys ["lbl_for_rent", "lbl_rent"]
    inputs: [
      getLocationInput(onAutoSubmit),
      getCategoryInput(),
      getPriceInput(contractId),
      getSurfaceInput(),
    ],
    contractId,
  };
};

const getAuctionForm = (
  isAlternate: boolean,
  onAutoSubmit: (values: BaseEntityWithChildren[] | GeoJSON) => void
): HomepageForm => {
  const contractId = CONTRACT.ASTE;

  return {
    text:
      isFeatureEnabled('EVALUATE_BUILDING') && !isAlternate
        ? 'lbl_for_auction'
        : 'lbl_auction', // i18n-extract-keys ["lbl_auction", "lbl_for_auction"]
    inputs: [
      getAuctionsLocationInput(onAutoSubmit),
      getCategoryInput(),
      getPriceInput(contractId),
      getSurfaceInput(),
    ],
    contractId,
  };
};

const getAppraiseForm = (isAlternate: boolean): HomepageForm => {
  return {
    text:
      isFeatureEnabled('SELLER_LEAD') && !isAlternate
        ? 'lbl_evaluate'
        : 'ftr_slim_appraise_house', // i18n-extract-keys ["ftr_slim_appraise_house", "lbl_evaluate"]
    inputs: [getAutocompleteInput('national')],
  };
};

const sellerLeadForm: HomepageForm = {
  text: 'lbl_sell', // i18n-extract-keys ["lbl_sell"]
  inputs: [getAutocompleteInput('national')],
  isNew: false,
};

const myHomeForm: HomepageForm = {
  text: 'mh.main_label', // i18n-extract-keys ["mh.main_label"]
  inputs: [getAutocompleteInput('myHome')],
  isNew: true,
};

const getAgenciesForm = (pageLookup: PageLookup): HomepageForm => {
  const hasAgencyNameInput = isFeatureEnabled('AGENCY_NAME_SEARCH');
  const associations =
    pageLookup?.associations && pageLookup.associations.length > 0
      ? getAssociationsInput(pageLookup.associations)
      : null;
  const franchising =
    pageLookup?.associations && pageLookup.franchising.length > 0
      ? getAgencyGroupsInput(pageLookup.franchising)
      : null;

  const agencyNameInput = hasAgencyNameInput ? getAgenciesSearchInput() : null;

  return {
    text: 'lbl_agency', // i18n-extract-keys ["lbl_agency", "lbl_agencies"]
    inputs: [
      getAgenciesLocationInput(!hasAgencyNameInput),
      agencyNameInput,
      associations,
      franchising,
    ].filter(nonNullable),
  };
};

const getInternationalForm = (
  contractId: CONTRACT,
  onAutoSubmit: (values: BaseEntityWithChildren[] | GeoJSON) => void
): HomepageForm => {
  return {
    text: contractId === CONTRACT.VENDITA ? 'lbl_for_sale' : 'lbl_for_rent', // i18n-extract-keys ["lbl_for_sale", "lbl_for_rent"]
    inputs: [
      isFeatureEnabled('INTERNATIONAL_SEARCH_ENABLED')
        ? getLocationInput(onAutoSubmit)
        : getAutocompleteInput('international'),
      isFeatureEnabled('INTERNATIONAL_SEARCH_ENABLED')
        ? getCategoryInternationalInput()
        : getCategoryResidentialInput(),
      getPriceInput(contractId),
      getSurfaceInput(),
    ],
    contractId,
  };
};

const getHolidayForm = (): HomepageForm => ({
  text: 'lbl_for_sale', // i18n-extract-keys ["lbl_for_sale"]
  inputs: [
    getAutocompleteInput('holiday'),
    getCategoryResidentialInput(),
    getPriceInput(CONTRACT.VENDITA),
    getSurfaceInput(),
  ],
  contractId: CONTRACT.VENDITA,
});

export const getSecondaryFields = (
  isMobile: boolean,
  form: HomepageForm
): HomepageForm['inputs'] => {
  if (isMobile) {
    return form.inputs.filter(
      (v) =>
        (v.type === 'range' && v.field !== FormFields.SURFACE) ||
        v.type === 'select'
    );
  }

  return form.inputs.filter((v) => v.type === 'range' || v.type === 'select');
};
