import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { useAppLink } from 'src/hooks/useAppLink';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import { capitalize } from 'src/utils/string';

import imgDevices from 'src/views/Homepage/images/download-app-devices.svg';
import imgAppIcon from 'src/views/Homepage/images/download-app-icon.svg';

import css from './styles.module.scss';

export function DownloadApp({ customClass }: { customClass?: string }) {
  const { trans } = useTranslations();

  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const { platform, storeUrl } = useAppLink(true);

  const domainName = getProductConfig('domainName');

  let icon = '';

  switch (platform) {
    case 'iOS':
      icon = 'apple';
      break;
    case 'Android':
      icon = 'google-play';
      break;
    case 'Huawei':
      icon = 'huawei';
      break;
    default:
      break;
  }

  return platform &&
    storeUrl &&
    isFeatureEnabled('DOWNLOAD_PROMOTIONAL_BANNER') ? (
    <div className={clsx('nd-grid', customClass)}>
      <div className={css['hp-downloadApp']}>
        <picture className={css['hp-downloadApp__picture']}>
          <source
            media="(min-width: 768px)"
            srcSet={imgDevices}
            width="385"
            height="271"
          />
          <img
            src={imgAppIcon}
            alt={trans('hp_generic_box_app_title')}
            width="96"
            height="96"
          />
        </picture>
        <div className={css['hp-downloadApp__text']}>
          <div className={css['hp-downloadApp__title']}>
            {trans('hp_generic_box_app_title')}
          </div>
          <p className={css['hp-downloadApp__description']}>
            {trans('hp_generic_box_app_paragraph')}
          </p>
          <p className={css['hp-downloadApp__description']}>
            {trans('hp_generic_box_app_paragraph2', {
              params: [capitalize(domainName)],
            })}
          </p>
          <PepitaButton
            as="a"
            block={maxScreenWidthSm}
            customClass={css['hp-downloadApp__button']}
            icon={{
              name: icon,
            }}
            href={storeUrl}
            target="_blank"
            rel="nofollow noopener"
            title={trans('hp_generic_box_app_title')}
            iconAlignment="right"
          >
            {trans('act_download_on')}
          </PepitaButton>
        </div>
      </div>
    </div>
  ) : null;
}
