import { useEffect, useState } from 'react';

export const useIsTouchOnlyDevice = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const media = matchMedia('(hover:none), (pointer: coarse)');

    setValue(media.matches);

    function handleChange() {
      setValue(media.matches);
    }

    media.addListener(handleChange);

    return () => media.removeListener(handleChange);
  }, []);

  return value;
};
