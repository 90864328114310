import type { Option } from '@pepita-react/select/src/types';

export type SORT_DIRECTION = 'asc' | 'desc';

export enum SORT_CRITERION {
  AUTOMATIC = 'automatico',
  RELEVANCE = 'rilevanza',
  PRICE = 'prezzo',
  SURFACE = 'superficie',
  ROOMS = 'locali',
  MODIFICATION_DATE = 'dataModifica',
  CREATION_DATE = 'data',
}

export enum SORT_VALUES {
  AUTOMATIC = 'automatic',
  MORE_RELEVANT = 'more-relevant',
  MORE_EXPENSIVE = 'more-expensive',
  LARGER = 'larger',
  MORE_LOCAL = 'more-local',
  LATEST = 'latest',
  LESS_EXPENSIVE = 'less-expensive',
  LESS_BIG = 'less-big',
  LESS_LOCAL = 'less-local',
  OLDER = 'older',
}

export interface SortingSelectOption extends Option {
  value: SORT_VALUES;
}

export interface SortingSelectConfigItem extends SortingSelectOption {
  criterion: SORT_CRITERION | null;
  direction?: SORT_DIRECTION;
}
