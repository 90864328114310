import { memo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';

import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';
import { homepageFormStateAtom } from '../atoms/homepageFormStateAtom';

import type { inputConfigType } from '../config/formConfigTypes';

import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import type { FieldType } from '../types';

export const InputField = memo(function InputField({
  fieldConfig,
  customClass,
  selectedClass,
  onChange,
}: FieldType<inputConfigType>) {
  const { trans } = useTranslations();

  const homepageFormKey = useAtomValue(homepageFormKeyAtom);
  const homepageFormState = useAtomValue(homepageFormStateAtom);

  return (
    <PepitaTextField
      name={fieldConfig.field}
      type="text"
      placeholder={
        fieldConfig.label
          ? // i18n-extract-disable-next-line
            trans(fieldConfig.label)
          : undefined
      }
      customClass={clsx(customClass, selectedClass)}
      onChange={(selection) => {
        onChange && onChange(fieldConfig.field, selection.target.value);
      }}
      value={
        (homepageFormState[homepageFormKey] &&
          homepageFormState[homepageFormKey][fieldConfig.field]) ??
        ''
      }
    />
  );
});
