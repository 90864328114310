import { useTransition } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtom } from 'jotai';

import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';

import type { HomepageForm, HomepageForms } from '../types';
import { HOMEPAGE_FORM_KEYS } from '../types';

import css from './styles.module.scss';

export function SearchContract({ forms }: { forms: HomepageForms }) {
  const { trans } = useTranslations();
  const [homepageFormKey, setHomepageFormKey] = useAtom(homepageFormKeyAtom);
  const [, startTransition] = useTransition();
  const tabChangeHandler = (tab: HOMEPAGE_FORM_KEYS) => {
    startTransition(() => {
      setHomepageFormKey(tab);
    });
  };

  return (
    <nav
      className={clsx('nd-tabBar', css['hp-searchContract'])}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="tablist"
      aria-label="search-form"
    >
      {Object.keys(forms).map((key: string, formIndex: number) => {
        const form: HomepageForm | undefined = forms[key];
        const formText = form?.text ?? null;

        return (
          formText && (
            <button
              key={formIndex}
              onClick={() => tabChangeHandler(key as HOMEPAGE_FORM_KEYS)}
              role="tab"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  tabChangeHandler(key as HOMEPAGE_FORM_KEYS);
                }
              }}
              type="button"
              aria-selected={homepageFormKey === key}
              id={`${key}-tab`}
              aria-controls={key}
              className={clsx(
                'nd-tab',
                css['hp-searchContract__link'],
                homepageFormKey === key && css['is-active'],
                form?.isNew && 'nd-badgeContainer'
              )}
            >
              {
                /* i18n-extract-disable */
                trans(formText, {
                  count: key === HOMEPAGE_FORM_KEYS.AGENCIES ? 2 : 1,
                  capitalize: true,
                })

                /* i18n-extract-enable */
              }
              {form?.isNew && (
                <PepitaBadge
                  variant="promotion"
                  customClass={css['hp-searchContract__badge']}
                  position="topRight"
                >
                  {/* For product requests, we do not put the translation key but a static label for any product/language */}
                  New
                </PepitaBadge>
              )}
            </button>
          )
        );
      })}
    </nav>
  );
}
