import { useTranslations } from '@pepita-react/i18n';

import belgiumImg from './images/belgium.svg';
import franceImg from './images/france.svg';
import germanyImg from './images/germany.svg';
import luxembourgImg from './images/luxembourg.svg';

export const useCountryConfig = (urlPrefix: string) => {
  const { trans } = useTranslations();

  const luxembourg = trans('lbl_nation_luxembourg');
  const france = trans('lbl_nation_france');
  const belgium = trans('lbl_nation_belgium');
  const germany = trans('lbl_nation_germany');

  const country = [
    {
      figure: luxembourgImg,
      country: 'luxembourg',
      countryString: luxembourg,
      countrySlug: 'luxembourg',
    },
    {
      figure: franceImg,
      country: 'france',
      countryString: france,
      countrySlug: 'france',
    },
    {
      figure: belgiumImg,
      country: 'belgium',
      countryString: belgium,
      countrySlug: 'belgique',
    },
    {
      figure: germanyImg,
      country: 'germany',
      countryString: germany,
      countrySlug: 'allemagne',
    },
  ];

  return country.map((data) => ({
    figure: data.figure,
    title: data.countryString,
    subtitle: trans('search_for_properties_in', {
      params: [data.countryString],
    }),
    url: `${urlPrefix}/${data.countrySlug}-pays/?criterio=rilevanza`,
    country: data.country,
  }));
};
