import { useCallback, useEffect, useMemo, useState } from 'react';
import type { GeoJSON } from '@indomita-website/search/types';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/GeographySearch';
import type { BaseEntityWithChildren } from 'src/components/GeographySearch/types';
import { geographyVRTToSearchData } from 'src/components/GeographySearch/utils';

import { getHomepageForms } from './config/formConfig';

import { useRestoreGeographySearch } from './hooks/useRestoreGeographySearch';
import { useSubmitHandler } from './hooks/useSubmitHandler';
import { useSyncGeographyBetweenTabs } from './hooks/useSyncGeographyBetweenTabs';

import type { GeographySearch } from 'src/libs/geography';

import type { PageContent, PageLookup } from 'src/types/homepage';

import { useResetHomepageFormKey } from '../../hooks/useResetHomepageFormKey';

import { SearchContract } from './SearchContract';
import { SearchForm } from './SearchForm';

import css from './index.module.scss';

export function SearchBox({
  pageContent,
  pageLookup,
}: {
  pageContent: PageContent;
  pageLookup: PageLookup;
}) {
  const [autoSubmitDone, setAutosubmitDone] = useState<boolean>(false);

  const { onSubmit } = useSubmitHandler();
  const [geography, setGeography] =
    useAtomicStateStateAtom(geographySearchAtom);
  const onAutoSubmit = useCallback(
    (values: BaseEntityWithChildren[] | GeoJSON) => {
      if (Array.isArray(values)) {
        // search by zone or metro
        const newGeography: GeographySearch = {
          searchType: 'place',
          value: values.map((e) => ({
            id: e.id,
            type: e.type,
            label: e.label,
            parents: e.parents ?? [],
            children: e.children ?? [],
          })),
        };

        setGeography(newGeography);
      } else {
        setGeography(
          geographyVRTToSearchData({
            searchType: 'vrt',
            value: values,
          })
        );
      }

      setAutosubmitDone(true);
    },
    [setGeography]
  );

  useEffect(() => {
    if (!autoSubmitDone) return;
    onSubmit();
  }, [geography, autoSubmitDone, onSubmit]);

  const forms = useMemo(
    () => getHomepageForms(pageContent, pageLookup, onAutoSubmit),
    [onAutoSubmit, pageContent, pageLookup]
  );

  useResetHomepageFormKey(pageContent);

  // Sync the geography state when switching tabs
  useSyncGeographyBetweenTabs();

  useRestoreGeographySearch();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={css['hp-searchBox']}>
      <SearchContract forms={forms} />
      <SearchForm forms={forms} />
    </div>
  );
}

SearchBox.displayName = 'SearchBox';
