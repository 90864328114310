export enum LAST_THINGS_TYPES {
  SEARCHES = 'searches',
  REALESTATES = 'real-estates',
}

interface Response {
  count: Nullable<number>;
}

export interface LastRealEstatesItem {
  title: Nullable<string>;
  subTitle: Nullable<string>;
  zones: Nullable<string>;
  figure: Nullable<string>;
  price: string;
  url: string;
  features: {
    locals: Nullable<number>;
    surface: string;
  };
  fkComune?: string;
  fkMacrozonaV2?: string;
}

export interface LastRealEstatesResponse extends Response {
  items: LastRealEstatesItem[];
}

export interface LastSearchesItem {
  title: string;
  subTitle: string;
  url: string;
  figure?: string;
  typologies: Nullable<string>;
  rangePrice: string;
  rangeSurface: string;
}

export interface LastSearchesResponse extends Response {
  items: LastSearchesItem[];
}

interface LocalStorageItemBase {
  dispositivo: number;
  time: number;
  dataUltimaVisita: string;
}

interface LocalStorageLastRealEstateProject extends LocalStorageItemBase {
  idProgetto: string;
}

interface LocalStorageLastRealEstateAd extends LocalStorageItemBase {
  idAnnuncio: string;
}

export type LocalStorageLastRealEstate = (
  | LocalStorageLastRealEstateAd
  | LocalStorageLastRealEstateProject
)[];
