import { useMemo } from 'react';
import { SearchMode } from '@indomita-website/search/types';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import { geographySearchToggleMap } from 'src/components/GeographySearch/utils';

import { getProductConfig } from 'src/config/product';

import { useIsAlternate } from 'src/hooks/useIsAlternate';

import type { DiscoverItem } from 'src/types/homepage';
import { DISCOVER_ITEM_TYPES } from 'src/types/homepage';
import type { Language } from 'src/types/translations';

import {
  absoluteURL,
  alternateAbsoluteURL,
  alternateExternalUrl,
} from 'src/utils/url';

import drawOnMapImage from '../images/disegna-mappa.svg';
import cityGuideImage from '../images/mercato-immobiliare.svg';
import mortgageImage from '../images/mutui.svg';
import myHomeImage from '../images/my-home.svg';
import publishAdImage from '../images/pubblica-annuncio.svg';
import evaluateBuildingImage from '../images/valutazione-immobiliare.svg';

export function useDiscoverConfig(): DiscoverItem[] {
  const isAlternate = useIsAlternate();
  const { trans } = useTranslations();
  const { locale, defaultLocale } = useRouter();

  const discoverConfig: DiscoverItem[] = getProductConfig(
    isAlternate ? 'alternateDiscoverUrls' : 'discoverUrls'
  );

  const image = (type: DISCOVER_ITEM_TYPES) => {
    switch (type) {
      case DISCOVER_ITEM_TYPES.EVALUATION_BUILDING:
        return evaluateBuildingImage;
      case DISCOVER_ITEM_TYPES.CITYGUIDE:
        return cityGuideImage;
      case DISCOVER_ITEM_TYPES.PUBLISH_AD:
        return publishAdImage;
      case DISCOVER_ITEM_TYPES.DRAW_ON_MAP:
        return drawOnMapImage;
      case DISCOVER_ITEM_TYPES.MORTGAGE:
        return mortgageImage;
      case DISCOVER_ITEM_TYPES.MYHOME:
        return myHomeImage;
      default:
        return;
    }
  };

  const actions = (
    type: DISCOVER_ITEM_TYPES,
    url?: string,
    isExternalBaseUrl?: boolean
  ) => {
    switch (type) {
      case DISCOVER_ITEM_TYPES.DRAW_ON_MAP:
        return {
          onClick: () => {
            geographySearchToggleMap(SearchMode.DrawPolygon);
          },
        };
      case DISCOVER_ITEM_TYPES.PUBLISH_AD:
        return {
          url: url ? absoluteURL(url) : undefined,
        };
      case DISCOVER_ITEM_TYPES.EVALUATION_BUILDING:
      case DISCOVER_ITEM_TYPES.CITYGUIDE:
      case DISCOVER_ITEM_TYPES.MORTGAGE:
      default:
        if (!url) return {};

        return {
          url: isExternalBaseUrl
            ? alternateExternalUrl(
                url,
                defaultLocale as Language,
                locale as Language
              )
            : alternateAbsoluteURL(
                url,
                defaultLocale as Language,
                locale as Language
              ),
        };
    }
  };

  return useMemo(() => {
    return discoverConfig.map((config) => ({
      ...actions(config.type, config.url, config.externalUrl),
      // i18n-extract-disable
      title: trans(config.title),
      text: trans(config.text),
      action: trans(config.action),
      // i18n-extract-enable
      image: image(config.type),
      type: config.type,
      isNew: config.isNew,
    }));
  }, [locale, defaultLocale]);
}
