import { SvgText } from './SvgText';

export function Title({
  showSvgHeadline,
  headline,
  customClass,
  onClickText,
}: {
  showSvgHeadline?: boolean;
  headline: string;
  customClass: string;
  onClickText?: () => void;
}) {
  return showSvgHeadline ? (
    <SvgText
      headline={headline}
      onClickText={onClickText ? () => onClickText() : undefined}
      customClass={customClass}
    />
  ) : (
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    <p
      className={customClass}
      onClick={onClickText ? () => onClickText() : undefined}
    >
      {headline}
    </p>
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  );
}
