import clsx from 'clsx';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { LocaleSelector } from '../LocaleSelector';

import { FooterTopList } from './FooterTopList';
import type { FooterTopListItemProps } from './FooterTopListItem';

export interface FooterTopSegmentProps {
  title: string;
  icon?: PepitaIconProps;
  list?: FooterTopListItemProps[];
  localeSelector?: boolean;
}

export function FooterTopSegment({
  direction,
  title,
  icon,
  list,
  localeSelector,
}: { direction: 'row' | 'column' } & FooterTopSegmentProps) {
  return (
    <div
      className={clsx(
        FooterTopStyles.segment,
        icon && FooterTopStyles.segmentWithIcon
      )}
    >
      {direction !== 'row' && icon && icon.name && (
        <PepitaIcon {...icon} customClass={FooterTopStyles.segmentIcon} />
      )}
      <span className={FooterTopStyles.segmentTitle}>{title}</span>
      {list && <FooterTopList list={list} direction={direction} />}
      {localeSelector && <LocaleSelector placement="top" />}
    </div>
  );
}

enum FooterTopStyles {
  segment = 'nd-footerSegment',
  segmentWithIcon = 'nd-footerSegment--withIcon',
  segmentIcon = 'nd-footerSegment__icon',
  segmentTitle = 'nd-footerSegment__title',
}
