import { useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';

import { geographySearchAtom } from 'src/components/GeographySearch';

import type { GeographySearch } from 'src/libs/geography';

import { HOMEPAGE_FORM_KEYS } from '../types';

import { isValidAgencyGeography, isValidAuctionGeography } from '../utils';

/**
 * As the geography state must be kept in sync between tabs with some exceptions for
 * the agencies tabs, we need to reset the atom according to the tab (restoring it under some
 * conditions)
 */
export const useSyncGeographyBetweenTabs = () => {
  const [geography, setGeography] =
    useAtomicStateStateAtom(geographySearchAtom);

  // Managing backup state between forms
  const [realEstateSearchState, setRealEstateSearchState] =
    useState<GeographySearch | null>(geography);

  // Using a boolean flag to distiguish between geographies changed by the user
  // and geographies changed due to form reset
  const geographyHasBeenReset = useRef(false);
  const homepageFormKey = useAtomValue(homepageFormKeyAtom);

  useEffect(() => {
    // When we navigate from a tab where the state has been reset to another
    // (e.g. from agencies to auctions) we need to check the backup state
    // instead of the global one
    const geographyToCheck = geographyHasBeenReset.current
      ? realEstateSearchState
      : geography;

    // Changing the current geography state according to the current tab
    if (
      (homepageFormKey === HOMEPAGE_FORM_KEYS.AGENCIES &&
        !isValidAgencyGeography(geographyToCheck)) ||
      (homepageFormKey === HOMEPAGE_FORM_KEYS.AUCTION &&
        !isValidAuctionGeography(geographyToCheck))
    ) {
      // We have a newly set invalid geography for agencies or
      // auctions, so we back up the data for the current tab
      // and reset the geography atom
      geographyHasBeenReset.current = true;
      setGeography(null);
      setRealEstateSearchState(geographyToCheck);
    } else {
      setGeography(realEstateSearchState);
      geographyHasBeenReset.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homepageFormKey]);

  useEffect(() => {
    if (!geography && geographyHasBeenReset.current) {
      // The geography has changed only for a tab change so we won't
      // keep in sync the backup state
      return;
    }

    // We need to keep in sync the backup state with the current one,
    // except for the cases where we are on the agencies or auction tabs and
    // the selection is not valid
    const isAuctionFormInvalid =
      homepageFormKey === HOMEPAGE_FORM_KEYS.AUCTION &&
      !isValidAuctionGeography(geography);
    const isAgenciesFormInvalid =
      homepageFormKey === HOMEPAGE_FORM_KEYS.AGENCIES &&
      !isValidAgencyGeography(geography);

    if (isAuctionFormInvalid || isAgenciesFormInvalid) {
      geographyHasBeenReset.current = true;
      setGeography(null);
    }

    setRealEstateSearchState(geography);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geography]);
};
