import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { getProductConfig } from 'src/config/product';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL } from 'src/utils/url';

import { useHomepageTracking } from '../../hooks/useHomepageTracking';

import css from './styles.module.scss';

const leaderUrl = getProductConfig('leaderUrl');

export function NumberOneLink({ customClass }: { customClass?: string }) {
  const { trans } = useTranslations();
  const { locale, defaultLocale } = useRouter();
  const { trackBannerClick } = useHomepageTracking();

  if (leaderUrl) {
    const handleClick = () => {
      // setTimeout was added to prevent window.open from causing a long task
      setTimeout(() => {
        window.open(
          alternateAbsoluteURL(
            leaderUrl,
            defaultLocale as Language,
            locale as Language
          ),
          '_blank'
        );
      }, 0);

      trackBannerClick('Banner');
    };

    return (
      <div className={clsx(css['hp-numberOneButton'], customClass)}>
        <PepitaBadge variant="brand">
          {trans('number_one_btn_badge')}
        </PepitaBadge>
        <span className={css['hp-numberOneButton__wrapper']}>
          {trans('number_one_btn_text')}
          <a
            className={css['hp-numberOneButton__link']}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                handleClick();
              }
            }}
            onClick={handleClick}
          >
            {trans('number_one_cta')}
          </a>
        </span>
        <a
          className={css['hp-numberOneButton__arrow']}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              handleClick();
            }
          }}
          onClick={handleClick}
        >
          <PepitaIcon name="arrow-right" />
        </a>
      </div>
    );
  }

  return null;
}

export function NumberOneLinkSkeleton() {
  return <div className={css['hp-numberOneButton']}></div>;
}
