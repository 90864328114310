import { useEffect, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useRouter } from 'next/router';

import type { LastRealEstatesResponse } from 'src/types/last-thing';
import { LAST_THINGS_TYPES } from 'src/types/last-thing';
import type { Language } from 'src/types/translations';

import { http } from 'src/utils/client/http';
import { serializeIntoUrl } from 'src/utils/querystring';

/**
Fetches last real estates

@param lang - The locale language.

@param isAuthenticated - Whether the user is authenticated or not.

@returns The response contain the last real estates data, or null if user not authenticated and no local data
*/
const fetchLastRealEstates = (lang: Language, isAuthenticated: boolean) => {
  const localStorageData = localStorage.getItem('imm_ultimiAnnunci');

  if (!isAuthenticated && !localStorageData) {
    return null;
  }

  return http
    .post(
      serializeIntoUrl(`/api-next/last/${LAST_THINGS_TYPES.REALESTATES}/`, {
        imm_source: 'homepage',
        __lang: lang,
      }),
      {
        json: {
          localStorage: localStorageData,
        },
      }
    )
    .json<LastRealEstatesResponse>()
    .then((response) => response);
};

export const useLastThingRealEstates = () => {
  const { user } = useAuthContext();
  const { locale } = useRouter();

  const [lastRealEstates, setlastRealEstates] =
    useState<Nullable<LastRealEstatesResponse>>(null);

  const [fetched, setFetched] = useState<boolean>(false);

  useEffect(() => {
    const result = fetchLastRealEstates(locale as Language, Boolean(user));

    result !== null
      ? result
          .then((result) => {
            setlastRealEstates(result);
            setFetched(true);
          })
          .catch(() => setFetched(true))
      : setFetched(true);
  }, [locale, user]);

  return { lastRealEstates, fetched, isAuthenticated: Boolean(user) };
};
