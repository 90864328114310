import Link from 'next/link';

import type { FooterTopListItemProps } from './FooterTopListItem';

interface ContentItemListProps extends FooterTopListItemProps {
  handleLinkClick: (e: React.SyntheticEvent<HTMLElement, Event>) => void;
}

export function ContentItemList({
  text,
  link,
  isClientSideNavigation,
  handleLinkClick,
}: ContentItemListProps) {
  if (link) {
    const anchor = (
      <a
        className={FooterTopStyles.listLink}
        target={link.target}
        href={!isClientSideNavigation ? link.href : undefined}
        rel={link.rel}
        title={link.title}
        onClick={handleLinkClick}
      >
        {text}
      </a>
    );

    return isClientSideNavigation ? (
      <Link href={link.href} passHref legacyBehavior>
        {anchor}
      </Link>
    ) : (
      anchor
    );
  }

  return <>{text}</>;
}

enum FooterTopStyles {
  listLink = 'nd-list__link',
}
