import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import Link from 'next/link';

import { Features, MainFeatures } from 'src/components/Features';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectLink,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import type { LastRealEstatesItem } from 'src/types/last-thing';
import { LAST_THINGS_TYPES } from 'src/types/last-thing';

import { useLastThingRealEstates } from '../../hooks/useLastThingRealEstates';
import { LastThingsSkeleton } from '../LastThingSkeleton';
import { LastThingSlider } from '../LastThingSlider';
import { LastThingTitle } from '../LastThingTitle';

import css from './styles.module.scss';

export function LastRealEstatesSection({
  customClass,
  titleClass,
  stripeCustomClass,
}: {
  customClass?: string;
  titleClass?: string;
  stripeCustomClass?: string;
}) {
  const { trans } = useTranslations();
  const { lastRealEstates, fetched, isAuthenticated } =
    useLastThingRealEstates();
  const lastRealEstatesSlides = getRealEstateItemSlides(
    lastRealEstates?.items || []
  );

  return (
    <section
      className={customClass}
      data-cy={`last-things-${LAST_THINGS_TYPES.REALESTATES}`}
    >
      {fetched ? (
        <>
          <LastThingTitle
            customClass={titleClass}
            isAuthenticated={isAuthenticated}
            link="/utente/annunci/recenti/"
            linkRef={trans('act_see_all')}
            text={trans('recently_viewed_ads')}
            icon="home"
            isVisible={Boolean(lastRealEstates?.count)}
          />
          <LastThingSlider
            type={LAST_THINGS_TYPES.REALESTATES}
            stripeCustomClass={stripeCustomClass}
          >
            {lastRealEstatesSlides}
          </LastThingSlider>
        </>
      ) : (
        <LastThingsSkeleton
          customClass={stripeCustomClass}
          titleClass={titleClass}
        />
      )}
    </section>
  );
}

const getImageCustomClass = () => {
  if (isFeatureEnabled('GENERIC_IMAGE')) {
    return 'nd-figure__placeholder--generic';
  }

  return undefined;
};

const getRealEstateItemSlides = (items: LastRealEstatesItem[]) => {
  return items.map((lastThing, index) => {
    const lastThingFeatures = {
      rooms: lastThing.features.locals ?? null,
      surface: lastThing.features.surface,
    };

    return (
      <PepitaStripe.Slide key={index}>
        <Link href={lastThing.url} passHref legacyBehavior>
          <PepitaMediaObjectLink
            target="_blank"
            customClass={css['hp-lastRealEstates__card']}
            hoverable
            padded
          >
            <PepitaFigure
              as="figure"
              figureContent={lastThing.figure}
              ratio="square"
              contentFull
              imageCustomClass={clsx(
                css['hp-lastRealEstates__figure'],
                getImageCustomClass()
              )}
            />
            <PepitaMediaObjectContent
              customClass={css['hp-lastRealEstates__content']}
            >
              <PepitaMediaObjectTop>
                <PepitaMediaObjectTitle
                  customClass={css['hp-lastRealEstates__title']}
                >
                  {lastThing.title}
                </PepitaMediaObjectTitle>
                {lastThing.subTitle && (
                  <p className={css['hp-lastRealEstates__subtitle']}>
                    {lastThing.subTitle}
                  </p>
                )}
                {lastThing.zones && (
                  <p className={css['hp-lastRealEstates__text']}>
                    {lastThing.zones}
                  </p>
                )}
              </PepitaMediaObjectTop>
              <PepitaMediaObjectBottom
                customClass={css['hp-lastRealEstates__bottom']}
              >
                {lastThing.price && (
                  <p className={css['hp-lastRealEstates__featuresPrice']}>
                    {lastThing.price}
                  </p>
                )}
                <Features size="compact">
                  <MainFeatures property={lastThingFeatures} />
                </Features>
              </PepitaMediaObjectBottom>
            </PepitaMediaObjectContent>
          </PepitaMediaObjectLink>
        </Link>
      </PepitaStripe.Slide>
    );
  });
};
