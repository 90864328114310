import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { Franchising as FranchisingType } from 'src/types/homepage';

import { GroupAgenciesCard } from '../GroupAgenciesCard';

import css from './styles.module.scss';

export function Franchising({
  franchising,
  customClass,
}: {
  franchising: FranchisingType;
  customClass?: string;
}) {
  const { trans } = useTranslations();
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleClick = () => {
    setShowAll(true);
  };

  return (
    <section
      className={clsx(css['hp-franchising'], customClass)}
      data-cy={'hp-franchising'}
    >
      <h1 className={css['hp-franchising__title']}>{franchising.title}</h1>
      {franchising.text.map((text, i) => {
        return (
          <p key={i} className={css['hp-franchising__description']}>
            {text}
          </p>
        );
      })}
      {franchising.items.map((item, i) => {
        return (
          <GroupAgenciesCard
            key={i}
            groupAgencies={item}
            hidden={i > 10 && !showAll ? true : false}
          />
        );
      })}
      {!showAll && (
        <PepitaButton
          centered
          customClass={css['hp-franchising__showAll']}
          onClick={() => handleClick()}
        >
          {trans('lbl_show_all')}
        </PepitaButton>
      )}
    </section>
  );
}
