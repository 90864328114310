import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { getDaysFromChristmas } from 'src/utils/client/christmas';

import css from './styles.module.scss';

const N_MAX_SNOWFLAKES = 100;
const N_MIN_SNOWFLAKES = 10;

interface SnowflakesProps {
  children: ReactNode;
}

const daysFromChristmasWithMaxValue = Math.min(
  getDaysFromChristmas() ?? Infinity,
  20
);

export function Snowflakes({ children }: SnowflakesProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const nSnowflakes = useMemo(() => {
    const nDefaultSnowflakes = Math.max(
      N_MIN_SNOWFLAKES,
      N_MAX_SNOWFLAKES - daysFromChristmasWithMaxValue * 4
    );

    return maxScreenWidthSm
      ? Math.round(nDefaultSnowflakes / 3)
      : nDefaultSnowflakes;
  }, [maxScreenWidthSm]);

  const snowflakes = useMemo(
    () => new Array(nSnowflakes).fill(null).map((_, i) => i),
    [nSnowflakes]
  );

  return (
    <section className={css['hp-snowflakes']}>
      <div className={css['hp-snowflakes__wrapper']} aria-hidden="true">
        {snowflakes.map((snowflake) => (
          <div key={snowflake} className={css['hp-snowflakes__item']}></div>
        ))}
      </div>
      <div>{children}</div>
    </section>
  );
}
