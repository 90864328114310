import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { getProductConfig } from 'src/config/product';

import { PepitaImage } from 'src/libs/ui/pepita-image';

import css from './styles.module.scss';

export function MainPartner({ customClass }: { customClass?: string }) {
  const { trans } = useTranslations();
  const mainPartner = getProductConfig('mainPartnerHome');

  return mainPartner ? (
    <section className={clsx('nd-grid', css['hp-mainPartner'], customClass)}>
      <a
        href={mainPartner.url}
        className={css['hp-mainPartner__link']}
        target={mainPartner.target}
        rel={mainPartner.rel}
      >
        {trans('lu_lbl_partner')}
        <PepitaImage
          customClass={css['hp-mainPartner__logo']}
          src={mainPartner.img.src_2x ?? mainPartner.img.src}
          srcSet={
            mainPartner.img.src_2x
              ? `${mainPartner.img.src}, ${mainPartner.img.src_2x} 2x`
              : undefined
          }
          alt={mainPartner.img.alt}
          width={mainPartner.img.width}
          height={mainPartner.img.height}
        />
      </a>
    </section>
  ) : null;
}
