import { memo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { AgencyLite } from 'src/types/real-estate';

import type { I18n } from 'src/utils/serverSideTrans';

import { capitalize } from '../../utils/string';

import css from './styles.module.scss';

interface AgencyPlaceholderProps {
  agencyType: AgencyLite['type'];
  bordered?: boolean;
  onlyIcon?: boolean;
  customClass?: string;
  compact?: boolean;
}

const getAgencyInfo = (
  agencyType: AgencyLite['type'],
  trans: I18n['trans']
) => {
  switch (agencyType) {
    case 'constructor':
      return {
        agencyIcon: 'helmet',
        agencyLabel: trans('lbl_construction_company'),
      };

    case 'court':
      return {
        agencyIcon: 'hammer',
        agencyLabel: trans('lbl_law_court'),
      };

    default:
      return {
        agencyIcon: 'suitcase',
        agencyLabel: trans('lbl_agency', { count: 1 }),
      };
  }
};

export const AgencyPlaceholder = memo(
  ({
    agencyType,
    bordered = false,
    onlyIcon = false,
    customClass,
    compact = false,
  }: AgencyPlaceholderProps) => {
    const { trans } = useTranslations();
    const { agencyIcon, agencyLabel } = getAgencyInfo(agencyType, trans);

    return (
      <span
        className={clsx(
          css['in-agencyPlaceholder'],
          bordered && css['in-agencyPlaceholder--bordered'],
          compact && css['in-agencyPlaceholder--compact'],
          customClass
        )}
      >
        <PepitaIcon
          name={agencyIcon}
          customClass={compact ? 'nd-icon--sm' : undefined}
        />
        {!onlyIcon && (
          <span className={css['in-agencyPlaceholder__text']}>
            {capitalize(agencyLabel)}
          </span>
        )}
      </span>
    );
  }
);

AgencyPlaceholder.displayName = 'AgencyPlaceholder';
