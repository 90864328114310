import { clsx } from 'clsx';

import type { FooterBottomProps } from './FooterBottom';
import { FooterBottom } from './FooterBottom';
import type { FooterTopProps } from './FooterTop';
import { FooterTop } from './FooterTop';

export type FooterProps = {
  customClass?: string;
  footerTop?: FooterTopProps;
  footerBottom?: FooterBottomProps;
  trackingKey?: string;
};

export function Footer(props: FooterProps) {
  const {
    customClass,
    footerBottom = {
      active: true,
      socials: true,
      disclaimer: true,
    },
    footerTop,
    trackingKey,
  } = props;

  return (
    <footer
      data-tracking-key={trackingKey}
      className={clsx(FooterStyles.footer, customClass ? customClass : null)}
    >
      {footerTop && <FooterTop {...footerTop} />}
      <FooterBottom {...footerBottom} />
    </footer>
  );
}

export enum FooterStyles {
  footer = 'nd-footer',
  bottom = 'nd-footerBottom',
  top = 'nd-footerTop',
  inner = 'nd-footer__inner',
  section = 'nd-footerSection',
  sectionSocial = 'nd-footerSection--social',
  disclaimer = 'nd-footerDisclaimer',
  social = 'nd-footerSocial',
  socialIcon = 'nd-footerSocial__icon',
}
