import { useMemo } from 'react';
import spriteHome from '@pepita-fe/sprite-footer/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import type { FooterTopListItemProps } from 'src/components/Footer/FooterTopListItem';
import type { FooterTopSegmentProps } from 'src/components/Footer/FooterTopSegment';

import type {
  FooterLink,
  FooterSection,
  FooterSegment,
  FooterSegmentCompact,
} from 'src/config/footer';
import { getFooterConfig } from 'src/config/footer';

import { useIsAlternate } from 'src/hooks/useIsAlternate';

import type { Language } from 'src/types/translations';

import type { Trans } from 'src/utils/i18nFormatters';
import { serializeIntoUrl } from 'src/utils/querystring';
import {
  absoluteURL,
  alternateAbsoluteURL,
  alternateExternalUrl,
} from 'src/utils/url';

const formatFooterConfig = (
  trans: Trans,
  isAlternate: boolean,
  defaultLocale: Language,
  locale: Language,
  asPath: string,
  footerGroup?: FooterLink[]
) => {
  return Object.keys(footerGroup || {})
    .map((_, key): Nullable<FooterTopListItemProps> => {
      if (!footerGroup) {
        return null;
      }

      const isAvailableToAlternate =
        isAlternate && footerGroup[key].alternate === false;
      const isAvailableOnlyToAlternate =
        !isAlternate && footerGroup[key].onlyAlternate;

      if (isAvailableToAlternate || isAvailableOnlyToAlternate) {
        return null;
      }

      // i18n-extract-disable
      const translatedLabel =
        isAlternate && footerGroup[key].labelAlt
          ? trans(footerGroup[key].labelAlt as string)
          : trans(footerGroup[key].label);
      const translatedTitle = footerGroup[key].title
        ? trans(footerGroup[key].title as string)
        : undefined;
      // i18n-extract-enable
      let href: string;

      if (footerGroup[key].label === 'RSS') {
        href = absoluteURL(
          serializeIntoUrl(asPath, {
            mode: 'rss',
          })
        );
      } else if (footerGroup[key].externalBaseUrl) {
        href = alternateExternalUrl(
          footerGroup[key].url,
          defaultLocale,
          footerGroup[key].avoidLocalizedUrl ? undefined : locale
        );
      } else if (footerGroup[key].avoidLocalizedUrl) {
        href = absoluteURL(footerGroup[key].url);
      } else {
        href = alternateAbsoluteURL(
          footerGroup[key].url,
          defaultLocale,
          locale
        );
      }

      //to remove link if a FF is not enabled
      if (footerGroup[key].isEnabled === false) {
        return null;
      }

      return {
        text: translatedLabel,
        link: {
          target: footerGroup[key].target,
          href,
          rel: footerGroup[key].rel,
          title: translatedTitle,
        },
        isClientOnlyLink: footerGroup[key].isClientOnlyLink,
        isClientSideNavigation: footerGroup[key].isClientSideNavigation,
      };
    })
    .filter(Boolean);
};

// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
function isFooterSegment(
  footer: FooterSegment | FooterSegmentCompact
): footer is FooterSegment {
  return Boolean((footer as FooterSegment).icon);
}

export const useFooterTopConfig = (
  type: FooterSection = 'homepage'
): FooterTopSegmentProps[][] => {
  const isAlternate = useIsAlternate();
  const { trans } = useTranslations();
  const { asPath, defaultLocale, locale } = useRouter() as {
    asPath: string;
    defaultLocale: Language;
    locale: Language;
  };

  const sections = useMemo(() => {
    let sections: FooterTopSegmentProps[][] | [] = getFooterConfig(
      type === 'homepage' ? 'segments' : 'segmentsCompact'
    )
      .map((section: FooterSegment[] | FooterSegmentCompact[]) => {
        return section
          .map((segment: FooterSegment | FooterSegmentCompact) => {
            const linkList = formatFooterConfig(
              trans,
              isAlternate,
              defaultLocale,
              locale,
              asPath,
              segment.links
            );

            return linkList.length
              ? {
                  title:
                    segment.name !== 'info'
                      ? trans(segment.title) // i18n-extract-disable-line
                      : segment.title,
                  list: linkList,
                  icon: isFooterSegment(segment)
                    ? {
                        name: segment.icon,
                        spriteUrl: spriteHome,
                      }
                    : undefined,
                }
              : null;
          })
          .filter(Boolean);
      })
      .filter(Boolean);

    if (type === 'homepage') {
      if (sections.length === 1) {
        sections.push([]);
      }

      if (sections.length < 1) {
        sections = [[]];
      }

      sections[sections.length - 1].push({
        title: trans('lbl_language'),
        localeSelector: true,
        icon: { name: 'world' },
      });
    }

    return sections;
  }, [asPath, defaultLocale, isAlternate, locale, trans, type]);

  return sections;
};
