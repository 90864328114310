import { memo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { TypologyMultiselect } from 'src/components/TypologyMultiselect';

import type { categoryResidentialConfigType } from '../config/formConfigTypes';
import { FormFields } from '../config/formConfigTypes';

import { CATEGORIES } from 'src/constants/typologies';

import { categoriesConfigAtom } from '../hooks/useCategoriesConfigAtom';

import type { FieldType } from '../types';

import { nonNullable } from 'src/utils/common';
import { getTypologiesFromCategorySelection } from 'src/utils/getTypologies';

export const TypologyResidentialField = memo(function TypologyField({
  customClass,
  controlCustomClass,
  selectedClass,
  onChange,
}: FieldType<categoryResidentialConfigType>) {
  const { trans } = useTranslations();

  const categoryConfig = useAtomicStateAtomValue(categoriesConfigAtom)
    .filter((category) => category.categoryId === CATEGORIES.RESIDENZIALE)
    .map((config) => ({
      selected: false,
      /* i18n-extract-disable-next-line */
      label: trans(config.label),
      value: config.value,
      children: config.children?.map((child) => ({
        selected: false,
        /* i18n-extract-disable-next-line */
        label: trans(child.label),
        value: `${child.categoryId}_${child.typologyId}`,
      })),
    }))
    .shift();

  return (
    <TypologyMultiselect
      customClass={clsx(customClass, selectedClass)}
      customControlClasses={controlCustomClass}
      options={categoryConfig?.children ?? []}
      onChange={(selection) => {
        if (!selection) {
          selection = [`${CATEGORIES.RESIDENZIALE}_`];
        }

        /**
         * Questo input gestisce sia il campo `category` che `typology` tramite un solo value.
         * Per convenzione abbiamo deciso di utilizzare il formato `categoryId_typologyId`, così quando selezioniamo un valore,
         * lo splittiamo per l'underscore e recuperiamo i singoli valori da inserire nello state del form.
         */
        const splittedSelections = selection.map((sel) => sel.split('_'));
        const typologies = splittedSelections
          .map(getTypologiesFromCategorySelection)
          .filter(nonNullable);

        const categoryId = splittedSelections[0][0];

        onChange && onChange(FormFields.CATEGORY, categoryId);
        onChange && onChange(FormFields.TYPOLOGY, typologies);
      }}
    />
  );
});
