import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';

import { getGeographyEntityID } from 'src/components/GeographySearch/utils';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import type { GeographySearch } from 'src/libs/geography';

export const isValidAgencyGeography = (
  geography: GeographySearch | null
): boolean => {
  const agencyByCountry = isFeatureEnabled('SEARCH_AGENCIES_COUNTRY')
    ? true
    : geography?.value[0]?.type !== ENTITY_TYPE.country;

  const agencyGeography =
    geography?.searchType === 'place' &&
    geography.value[0]?.type !== ENTITY_TYPE.region &&
    agencyByCountry;

  return (
    agencyGeography &&
    (isFeatureEnabled('INTERNATIONAL_SEARCH_DISABLE_AGENCIES')
      ? getGeographyEntityID(geography, ENTITY_TYPE.country) ===
        getProductConfig('countryCode')
      : true)
  );
};

export const isValidAuctionGeography = (
  geography: GeographySearch | null
): boolean => {
  if (isFeatureEnabled('INTERNATIONAL_SEARCH_DISABLE_AUCTIONS') && geography) {
    return (
      geography.searchType !== 'place' ||
      getGeographyEntityID(geography, ENTITY_TYPE.country) ===
        getProductConfig('countryCode')
    );
  }

  return true;
};
