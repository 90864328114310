import { useAuthContext } from '@indomita-react/auth-provider';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useIsAlternate } from 'src/hooks/useIsAlternate';

import { LAST_THINGS_TYPES } from 'src/types/last-thing';

import downloadAppGeneric from '../images/generic-placeholder/placeholder_download.svg';
import hideListingGeneric from '../images/generic-placeholder/placeholder_hideRe.svg';
import messageGeneric from '../images/generic-placeholder/placeholder_message.svg';
import publishGeneric from '../images/generic-placeholder/placeholder_publish.svg';
import saveListingGeneric from '../images/generic-placeholder/placeholder_saveRe.svg';
import saveSearchGeneric from '../images/generic-placeholder/placeholder_savesearch.svg';
import searchMapGeneric from '../images/generic-placeholder/placeholder_searchmap.svg';
import registerGeneric from '../images/generic-placeholder/placeholder_sincronize.svg';
import notificationsIcon from '../images/immo-empty-attiva-notifiche.svg';
import messageIcon from '../images/immo-empty-message.svg';
import myHomeIcon from '../images/immo-empty-my-home.svg';
import hideListingIcon from '../images/immo-empty-nascondi-annunci.svg';
import publishIcon from '../images/immo-empty-publish.svg';
import registerIcon from '../images/immo-empty-registrazione.svg';
import searchMapIcon from '../images/immo-empty-ricerca-mappa.svg';
import saveListingIcon from '../images/immo-empty-salva-annunci.svg';
import saveSearchIcon from '../images/immo-empty-salva-ricerca.svg';
import downloadAppIcon from '../images/immo-empty-scarica-app.svg';

export type LastThingsPlaceholder = {
  figure: string;
  title: string;
  description: string;
  isNew?: boolean;
};

export const useLastThingsPlaceholders = (
  type: LAST_THINGS_TYPES,
  mobileApp: boolean
): LastThingsPlaceholder[] => {
  const { user } = useAuthContext();
  const genericImage = isFeatureEnabled('GENERIC_IMAGE');
  const publishAds = isFeatureEnabled('PUBLISHADSPLACEHOLDER');
  const sellerLead = isFeatureEnabled('SELLER_LEAD');
  const myHomeEnabled = isFeatureEnabled('MY_HOME');
  const messagingInLastSearch = isFeatureEnabled('MESSAGING_IN_LAST_SEARCH');
  const messagingInLastThingsEnabled = isFeatureEnabled(
    'MESSAGING_IN_LAST_THINGS_ENABLED'
  );
  const isAlternate = useIsAlternate();
  const myHome = !isAlternate && myHomeEnabled;

  const messagingPlaceholder = {
    figure: genericImage ? messageGeneric : messageIcon,
    title: 'hp_placeholder_message_title', // i18n-extract-keys ["hp_placeholder_message_title"]
    description: 'hp_placeholder_message_description', // i18n-extract-keys ["hp_placeholder_message_description"]
  };

  const myHomePlaceholder = {
    figure: myHomeIcon,
    title: 'feature_strip_my_home_title', // i18n-extract-keys ["feature_strip_my_home_title"]
    description: 'feature_strip_my_home_text', // i18n-extract-keys ["feature_strip_my_home_text"]
    isNew: true,
  };

  if (LAST_THINGS_TYPES.SEARCHES === type) {
    let lastThingsSearches: LastThingsPlaceholder[] = [];

    if (!user) {
      lastThingsSearches.push({
        figure: genericImage ? registerGeneric : registerIcon,
        title: 'register_it_s_free', // i18n-extract-keys ["register_it_s_free"]
        description: 'register_now_info_abstract', // i18n-extract-keys ["register_now_info_abstract"]
      });
    }

    if (!genericImage && user) {
      lastThingsSearches.push({
        figure: notificationsIcon,
        title: 'act_enable_notifications', // i18n-extract-keys ["act_enable_notifications"]
        description: 'enable_notifications_info_abstract', // i18n-extract-keys ["enable_notifications_info_abstract"]
      });
    }

    lastThingsSearches = lastThingsSearches.concat([
      {
        figure: genericImage ? searchMapGeneric : searchMapIcon,
        title: 'act_search_on_map', // i18n-extract-keys ["act_search_on_map"]
        description: 'search_on_map_info_abstract', // i18n-extract-keys ["search_on_map_info_abstract"]
      },
      {
        figure: genericImage ? saveSearchGeneric : saveSearchIcon,
        title: 'act_save_search', // i18n-extract-keys ["act_save_search"]
        description: 'save_search_info_abstract', // i18n-extract-keys ["save_search_info_abstract"]
      },
    ]);

    if (sellerLead || publishAds) {
      lastThingsSearches.push({
        figure: genericImage ? publishGeneric : publishIcon,
        title: sellerLead
          ? 'hp_placeholder_sell_title'
          : 'hp_placeholder_publish_title', // i18n-extract-keys ["hp_placeholder_sell_title", "hp_placeholder_publish_title"]
        description: sellerLead
          ? 'hp_placeholder_sell_description'
          : 'hp_placeholder_publish_description', // i18n-extract-keys ["hp_placeholder_sell_description", "hp_placeholder_publish_description"]
      });
    }

    if (messagingInLastSearch && messagingInLastThingsEnabled) {
      lastThingsSearches.push(messagingPlaceholder);
    }

    return lastThingsSearches;
  } else if (LAST_THINGS_TYPES.REALESTATES === type) {
    const lastThingRealEstates: LastThingsPlaceholder[] = [
      {
        figure: genericImage ? saveListingGeneric : saveListingIcon,
        title: 'act_save_ads', // i18n-extract-keys ["act_save_ads"]
        description: 'save_ads_info_abstract', // i18n-extract-keys ["save_ads_info_abstract"]
      },
      {
        figure: genericImage ? hideListingGeneric : hideListingIcon,
        title: 'act_hide_ads', // i18n-extract-keys ["act_hide_ads"]
        description: 'hide_ads_info_abstract', // i18n-extract-keys ["hide_ads_info_abstract"]
      },
    ];

    if (mobileApp) {
      lastThingRealEstates.push({
        figure: genericImage ? downloadAppGeneric : downloadAppIcon,
        title: 'act_download_mobile_application_short', // i18n-extract-keys ["act_download_mobile_application_short"]
        description: 'download_app_info_abstract', // i18n-extract-keys ["download_app_info_abstract"]
      });
    }

    if (myHome) {
      lastThingRealEstates.push(myHomePlaceholder);
    }

    if (!myHome && !messagingInLastSearch && messagingInLastThingsEnabled) {
      lastThingRealEstates.push(messagingPlaceholder);
    }

    return lastThingRealEstates;
  }

  return [];
};
