import { useMemo, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import { getProductConfig } from 'src/config/product';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';
import type { PepitaSelectProps } from 'src/libs/ui/pepita-select';
import { PepitaSelect } from 'src/libs/ui/pepita-select';

import type { Language } from 'src/types/translations';

import { setCookie } from 'src/utils/document-cookie';
import { capitalize } from 'src/utils/string';
import { alternateAbsoluteURL } from 'src/utils/url';

const writeLangCookie = (lang: Language): void => {
  const hostname = window.location.hostname;

  setCookie(
    'imm_pl',
    lang,
    Date.now() + 31556926000, // 1 year
    hostname.substring(hostname.indexOf('.'))
  );
};

interface LocaleSelectorProps {
  placement: PepitaSelectProps['placement'];
}

export function LocaleSelector({ placement }: LocaleSelectorProps) {
  const { trans } = useTranslations();
  const domainName = getProductConfig('domainName');
  const allowedLanguages = getProductConfig('allowedLanguages');

  const { defaultLocale, locale, asPath } = useRouter() as {
    defaultLocale: Language;
    locale: Language;
    asPath: string;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(defaultLocale);

  const options = useMemo(() => {
    return Object.keys(allowedLanguages).map((code) => ({
      label: allowedLanguages[code].label,
      value: code,
    }));
  }, [allowedLanguages]);

  const closeDialog = () => {
    setIsModalVisible(false);
  };

  const onLocaleChange = () => {
    if (selectedLocale === defaultLocale) {
      closeDialog();
    }

    writeLangCookie(selectedLocale);

    window.location.href = alternateAbsoluteURL(
      asPath,
      defaultLocale,
      selectedLocale
    );

    closeDialog();
  };

  return (
    <>
      <PepitaSelect
        customClass="nd-footer__select"
        controlCustomClass="nd-footer__selectControl"
        title={trans('lbl_language')}
        options={options}
        onChange={(selection) => {
          setIsModalVisible(true);
          setSelectedLocale(selection.value as Language);
        }}
        value={options.find((option) => option.value === locale)}
        placeholder={options[locale]}
        confirmLabel={trans('act_confirm')}
        placement={placement}
      />

      {isModalVisible && (
        <PepitaDialog
          title={trans('changeLanguageModal.title', {
            capitalize: true,
          })}
          onClose={closeDialog}
          size={PepitaDialogFrameType.MEDIUM}
          content={trans('changeLanguageModal.description', {
            params: [capitalize(domainName)],
          })}
          footer={
            <>
              <PepitaButton onClick={closeDialog}>
                {trans('act_cancel')}
              </PepitaButton>
              <PepitaButton variant="accent" onClick={onLocaleChange}>
                {trans('lbl_change')}
              </PepitaButton>
            </>
          }
        />
      )}
    </>
  );
}
