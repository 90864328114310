import { useCallback, useMemo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';
import { useAtomValue, useSetAtom } from 'jotai';

import { homepageFormKeyAtom } from '../atoms/homepageFormKeyAtom';
import { homepageFormStateAtom } from '../atoms/homepageFormStateAtom';

import { getSecondaryFields } from '../config/formConfig';
import type { fieldConfigType } from '../config/formConfigTypes';
import { FormFields } from '../config/formConfigTypes';

import { useSubmitHandler } from '../hooks/useSubmitHandler';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { HomepageForm, HomepageForms } from '../types';
import { HOMEPAGE_FORM_KEYS } from '../types';

import { FormField } from '../FormField';

import css from './styles.module.scss';

export function SearchForm({ forms }: { forms: HomepageForms }) {
  const { trans } = useTranslations();
  const { onSubmit, submitState } = useSubmitHandler();

  const homepageFormKey = useAtomValue(homepageFormKeyAtom);

  const setHomepageFormState = useSetAtom(homepageFormStateAtom);

  const inputChangeHandler = useCallback(
    (field: string, value: string | string[] | number | null) => {
      setHomepageFormState((prevState) => ({
        ...prevState,
        [homepageFormKey]: {
          ...prevState[homepageFormKey],
          [FormFields.CONTRACT]: forms[homepageFormKey]?.contractId || null,
          [field]: value,
        },
      }));
    },
    [setHomepageFormState, homepageFormKey, forms]
  );

  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const secondaryFields = getSecondaryFields(
    maxScreenWidthSm,
    forms[homepageFormKey] as HomepageForm
  );

  const actText = () => {
    switch (homepageFormKey) {
      case HOMEPAGE_FORM_KEYS.SELLER:
        return trans('lbl_sell');
      case HOMEPAGE_FORM_KEYS.APPRAISE:
        return trans('act_evaluate');
      case HOMEPAGE_FORM_KEYS.MYHOME:
        return trans('act_start_alt');
      default:
        return trans('act_search');
    }
  };

  const buttonClassContainer = useMemo(() => {
    if (
      homepageFormKey === HOMEPAGE_FORM_KEYS.SELLER ||
      homepageFormKey === HOMEPAGE_FORM_KEYS.APPRAISE ||
      homepageFormKey === HOMEPAGE_FORM_KEYS.MYHOME
    ) {
      return css['is-rightTopRounded'];
    }

    return undefined;
  }, [homepageFormKey]);

  const getContainerCustomClass = (inputType: fieldConfigType['type']) => {
    if (inputType === 'autocomplete') {
      return css['hp-searchForm__autocomplete'];
    }

    if (inputType === 'place') {
      return css['hp-searchForm__autocompletePlace'];
    }

    return undefined;
  };

  return (
    <div
      id={homepageFormKey}
      role="tabpanel"
      aria-labelledby={`${homepageFormKey}-tab`}
      className={clsx(
        css['hp-searchForm'],
        submitState === 'error' && 'has-error'
      )}
      data-cy="search-form"
    >
      {(
        forms[homepageFormKey]?.inputs.filter(
          (v) => v.type !== 'range' && v.type !== 'select'
        ) || []
      ).map((inputConfig, inputIndex) => {
        return (
          <FormField
            key={`${homepageFormKey}-${inputIndex}`}
            fieldConfig={inputConfig}
            onChange={inputChangeHandler}
            customClass={clsx(
              css['hp-searchForm__item'],
              inputConfig.customClasses ?? null
            )}
            containerCustomClass={getContainerCustomClass(inputConfig.type)}
            iconCustomClass={
              inputConfig.type === 'autocomplete'
                ? css['hp-searchForm__autocompleteIcon']
                : undefined
            }
            controlCustomClass={clsx(inputConfig.controlCustomClasses ?? null)}
            error={submitState === 'error'}
          />
        );
      })}
      <div className={css['hp-searchForm__secondaryFilters']}>
        {secondaryFields.map((inputConfig, inputIndex) => {
          return (
            <FormField
              key={`${homepageFormKey}-${inputIndex}`}
              fieldConfig={inputConfig}
              onChange={inputChangeHandler}
              customClass={
                inputConfig.field === FormFields.SURFACE
                  ? css['hp-searchForm__secondaryFilters--hidden']
                  : undefined
              }
            />
          );
        })}
      </div>
      <div className={clsx(css['hp-searchForm__button'], buttonClassContainer)}>
        <PepitaButton
          variant="accent"
          onClick={onSubmit}
          block
          loading={submitState === 'loading'}
          disabled={submitState === 'loading'}
          data-cy="search-button"
        >
          {actText()}
        </PepitaButton>
      </div>
    </div>
  );
}
