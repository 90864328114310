//@ts-expect-error
import footer from '<product-footer>';

export type FooterSection = 'homepage' | 'compact';

export type FooterLink = {
  label: string;
  labelAlt?: string;
  url: string;
  isClientOnlyLink?: boolean;
  alternate?: boolean;
  onlyAlternate?: boolean;
  rel?: string;
  title?: string;
  externalBaseUrl?: boolean;
  target?: '_blank' | '_top';
  avoidLocalizedUrl?: boolean;
  isClientSideNavigation?: boolean;
  isEnabled?: boolean; //to enable or disable a footer entry in a specific environment
};

//configuration for top of footer in big version (columns link , ex. homepage)
export type FooterSegment = {
  title: string;
  name: string;
  icon: string;
  links: FooterLink[];
};

//configuration for top of footer in compact version (inline link, ex. list)
export type FooterSegmentCompact = Omit<FooterSegment, 'icon'>;

export type FooterConfigPath = 'segments' | 'segmentsCompact';

export const getFooterConfig = (key: FooterConfigPath) => {
  if (footer[key]) {
    return footer[key];
  }

  return [];
};
