import type {
  AgencyMarker,
  GenericMarker,
} from 'src/repository/search-map/types';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { type GeographySearch } from 'src/libs/geography';

import { ENTITY_TYPE } from 'src/types/geography';

export const isAgenciesProvinceEnabled = (
  cityId?: string | number,
  provinceId?: string | number
) => {
  // If the province is not enabled, we don't want to show the agencies marker in map and the default is that
  //  if the city is not enabled, we don't want to show the agencies marker
  if (
    (isFeatureEnabled('AGENCIES_EXPERT_SEARCH_LIST_PROVINCE') && provinceId) ||
    cityId
  ) {
    return true;
  }

  return false;
};

export const isMetroSearch = (geographyData: GeographySearch | null) => {
  if (!geographyData || geographyData.searchType !== 'place') {
    return false;
  }

  return geographyData.value.some(
    (entity) =>
      entity.type === ENTITY_TYPE.metro || entity.type === ENTITY_TYPE.metroLine
  );
};

export const getIdFromMarker = (value?: GenericMarker | AgencyMarker) => ({
  idGeoHash: value?.dataType === 'generic' ? value.idGH : undefined,
  listingId: value?.dataType === 'generic' ? value.id : undefined,
  agencyId: value?.dataType === 'agency' ? value.id : undefined,
});
