import type { SVGProps } from 'react';
import { clsx } from 'clsx';

import css from './index.module.scss';

export type BrandType = {
  noViewBox?: boolean;
  width?: number;
  height?: number;
  svgCustomClass?: string;
  svgProps?: SVGProps<SVGSVGElement>;
  imgProps?: SVGProps<SVGImageElement>;
  url: string;
  fragment?: string;
  fragmentAlt?: string;
};

export function Brand({
  noViewBox,
  width,
  height,
  svgCustomClass,
  svgProps,
  imgProps,
  url,
  fragment,
  fragmentAlt,
}: BrandType) {
  return (
    <svg
      className={clsx(css['in-brand__logo'], svgCustomClass)}
      viewBox={noViewBox ? '' : `0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMax meet"
      {...svgProps}
    >
      <image
        className={fragmentAlt && css['in-brand__logo--primary']}
        {...imgProps}
        href={url + (fragment ? `#${fragment}` : '')}
        width={width}
        height={height}
      />
      {fragmentAlt && (
        <image
          className={css['in-brand__logo--secondary']}
          {...imgProps}
          href={url + (fragmentAlt ? `#${fragmentAlt}` : '')}
        />
      )}
    </svg>
  );
}
