import type { ReactNode } from 'react';
import { createElement } from 'react';
import { clsx } from 'clsx';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import css from './index.module.scss';

export type FeatureItemProps = {
  icon?: PepitaIconProps;
  label?: string;
  customClass?: string;
  isMain?: boolean;
  content: ReactNode;
};

export function FeatureItem(props: FeatureItemProps) {
  // isMain identifies the price field.
  // check both the "content" and "isMain" to avoid empty "li" and bad graphics
  // check for features that have no content (no value) but simply illustrative (isActive prop) (es. lusso)
  return !props.content && props.isMain ? null : (
    <li
      className={clsx([
        'nd-list__item',
        css['in-feat__item'],
        props.isMain && css['in-feat__item--main'],
        props.customClass,
      ])}
      aria-label={props.label}
    >
      {props.isMain ? (
        props.content
      ) : (
        <>
          <div className={css['in-feat__data']}>
            {props.icon ? (
              <>
                {createElement(PepitaIcon, props.icon)}
                {props.content}
              </>
            ) : (
              props.content
            )}
          </div>
        </>
      )}
    </li>
  );
}
