import { PepitaList } from 'src/libs/ui/pepita-list';

import type { FooterTopListItemProps } from './FooterTopListItem';
import { FooterTopListItem } from './FooterTopListItem';

interface FooterTopListProps {
  direction: 'row' | 'column';
  list: FooterTopListItemProps[];
}

export function FooterTopList({ direction, list }: FooterTopListProps) {
  return (
    <PepitaList
      customClass={FooterTopStyles.segmentList}
      type={direction === 'row' ? 'pipe' : undefined}
      items={list.map((item, i) => {
        return (
          <FooterTopListItem
            key={i}
            text={item.text}
            link={item.link}
            isClientOnlyLink={item.isClientOnlyLink}
            isClientSideNavigation={item.isClientSideNavigation}
          />
        );
      })}
    />
  );
}

enum FooterTopStyles {
  segmentList = 'nd-footerSegment__list',
}
