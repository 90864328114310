/* eslint-disable react/display-name */
import { memo } from 'react';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import css from './styles.module.scss';

interface TitleProps {
  customClass?: string;
  isAuthenticated: boolean;
  text: string;
  icon: string;
  link: string;
  linkRef: string;
  isVisible: boolean;
}

export const LastThingTitle = memo(
  ({
    customClass,
    isAuthenticated,
    text,
    icon,
    link,
    linkRef,
    isVisible,
  }: TitleProps) => {
    return (
      <div
        className={clsx(css['hp-lastThingTitle__head'], 'nd-grid', customClass)}
      >
        {isVisible && (
          <>
            <PepitaIcon name={icon} />
            {text}
            {isAuthenticated ? (
              <PepitaButton
                variant="ghost"
                customClass={css['hp-lastThingTitle__link']}
                href={link}
                as={'a'}
                small
              >
                {linkRef}
              </PepitaButton>
            ) : null}
          </>
        )}
      </div>
    );
  }
);
