import { useMemo } from 'react';
import { join } from '@pepita/querystring';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import type { HeaderProps } from 'src/components/Header';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { useHeaderLinkConfig } from 'src/hooks/useHeaderLink';
import { useIsAlternate } from 'src/hooks/useIsAlternate';

import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL } from 'src/utils/url';

const useHeaderLink = () => {
  const { trans } = useTranslations();
  const { defaultLocale, locale } = useRouter();
  const isAlternate = useIsAlternate();
  const headerConfig = useHeaderLinkConfig();

  return useMemo(() => {
    const isEnabledSellerLead: boolean =
      isFeatureEnabled('SELLER_LEAD') && !isAlternate;

    const isEnabledMyHome: boolean =
      isFeatureEnabled('MY_HOME') && !isAlternate;

    const headerUrls = [...headerConfig];
    const sellerLeadUrl = getProductConfig('sellerLeadUrl');
    const myHomeUrl = getProductConfig('myHomeUrl');
    const findPosition = (element) =>
      element.text === trans('ftr_slim_appraise_house');

    if (isEnabledSellerLead && sellerLeadUrl) {
      const sellerLeadPosition: number = headerUrls.findIndex(findPosition);

      headerUrls.splice(sellerLeadPosition, 0, {
        isClientSideNavigation: false,
        text: trans('lbl_sell_house', { capitalize: true }),
        link: {
          url: alternateAbsoluteURL(
            join(sellerLeadUrl, 'from=navbar'),
            defaultLocale as Language,
            locale as Language
          ),
        },
        props: {
          rel: 'nofollow',
        },
        isNew: false,
      });
    }

    if (isEnabledMyHome && myHomeUrl) {
      const myHomePosition: number = headerUrls.findIndex(findPosition) + 1;

      headerUrls.splice(myHomePosition, 0, {
        isClientSideNavigation: false,
        text: trans('mh.main_label', { capitalize: true }),
        link: {
          url: alternateAbsoluteURL(
            join(myHomeUrl, 'from=navbar'),
            defaultLocale as Language,
            locale as Language
          ),
        },
        props: {
          rel: 'nofollow',
        },
        isNew: true,
      });
    }

    return headerUrls;
  }, [defaultLocale, locale, headerConfig]);
};

const headerConfig = (navbarUrl): HeaderProps => ({
  showMenu: true,
  menuItems: navbarUrl,
});

export const useHomeHeaderLinkConfig = () => {
  const navbarUrl = useHeaderLink();

  return useMemo(() => headerConfig(navbarUrl), [navbarUrl]);
};
