import { clsx } from 'clsx';

import type { FooterTopSegmentProps } from './FooterTopSegment';
import { FooterTopSegment } from './FooterTopSegment';
import { FooterStyles } from './index';

export type FooterTopProps = {
  customClass?: string;
  direction?: 'row' | 'column';
  sections: FooterTopSegmentProps[][];
};

export function FooterTop({
  customClass,
  direction = 'column',
  sections,
}: FooterTopProps) {
  if (!sections || !sections.length) {
    return null;
  }

  return (
    <div className={clsx(FooterStyles.top, customClass && customClass)}>
      <div className={FooterStyles.inner}>
        {sections.map((section, i) => (
          <div
            key={i}
            className={
              direction === 'row' ? FooterTopStyles.row : FooterTopStyles.column
            }
          >
            {section.map((segment, i) => (
              <FooterTopSegment
                key={i}
                direction={direction}
                title={segment.title}
                icon={segment.icon}
                list={segment.list}
                localeSelector={segment.localeSelector}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

enum FooterTopStyles {
  row = 'nd-footerTop__row',
  column = 'nd-footerTop__column',
}
