import type { SeoMapPanel } from 'src/types/homepage';

import { ListMeta } from '../ListMeta';

import css from './styles.module.scss';

export function PanelMeta({ panel }: { panel: SeoMapPanel }) {
  return (
    <>
      {panel.title ? (
        <div className={css['hp-panelMeta']}>
          {panel.link ? (
            <a href={panel.link} title={panel.linkTitle}>
              {panel.title}
            </a>
          ) : (
            panel.title
          )}
        </div>
      ) : null}
      <ListMeta list={panel.items} isColumn />
    </>
  );
}
