import type { GeoJSON } from '@indomita-website/search/types';

import type { BaseEntityWithChildren } from 'src/components/GeographySearch/types';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';

export enum FormFields {
  CONTRACT = 'idContratto',
  CATEGORY = 'idCategoria',
  PRICE = 'prezzo',
  MIN_PRICE = 'prezzoMinimo',
  MAX_PRICE = 'prezzoMassimo',
  SURFACE = 'superficie',
  MIN_SURFACE = 'superficieMinima',
  MAX_SURFACE = 'superficieMassima',
  TYPOLOGY = 'idTipologia',
  LANGUAGE = '__lang',
  PLACE = 'place',
  AUTOCOMPLETE = 'autocomplete',
  AUTOCOMPLETE_INTERNATIONAL = 'autocompleteInternational',
  AUTOCOMPLETE_HOLIDAY = 'autocompleteHoliday',
  AGENCY = 'keywords',
  AGENCY_GROUP = 'idFranchising',
  AGENCY_ASSOCIATION = 'fkAssociazione',
  SORT_CRITERION = 'criterio',
  MIN_NUMBER_OF_ROOMS = 'localiMinimo',
  MAX_NUMBER_OF_ROOMS = 'localiMassimo',
  MIN_BEDROOMS = 'camereDaLettoMinimo',
  MAX_BEDROOMS = 'camereDaLettoMassimo',
}

export type AutocompleteEndpoints =
  | 'national'
  | 'international'
  | 'holiday'
  | 'myHome';

export type baseFieldConfigType = {
  icon?: PepitaIconProps;
  field: FormFields;
  label: Nullable<string>;
  customClasses?: string;
  controlCustomClasses?: string;
};

export type categoryConfigType = baseFieldConfigType & {
  type: 'category';
};

export type categoryResidentialConfigType = baseFieldConfigType & {
  type: 'categoryResidential';
};

export type categoryInternationalConfigType = baseFieldConfigType & {
  type: 'categoryInternational';
};

export type rangeConfigType = baseFieldConfigType & {
  type: 'range';
  fromName: FormFields;
  toName: FormFields;
  translationKeys?: {
    label: string;
    fromValue: {
      label: string;
    };
    toValue: {
      label: string;
    };
    fromToValue: string;
    value: string;
    from: string;
    to: string;
    title: string;
  };
  keepZeros?: boolean;
  shortLabelValues?: boolean;
  conditionType: 'and' | 'or';
  range: {
    condition?: { [field in FormFields]?: (number | string)[] };
    min: number;
    max: number;
    step: {
      [_: number]: number | undefined;
    };
  }[];
};

export type selectConfigType = baseFieldConfigType & {
  type: 'select';
  options: { id: string; value: string }[];
  capitalize?: boolean;
};

export type placeConfigType = baseFieldConfigType & {
  type: 'place';
  drawEnabled?: boolean;
  metroEnabled?: boolean;
  regionSearchEnabled: boolean;
  countrySearchEnabled: boolean;
  internationalEnabled: boolean;
  autorefill?: boolean;
  onAutoSubmit?: (values: BaseEntityWithChildren[] | GeoJSON) => void;
};

export type inputConfigType = baseFieldConfigType & {
  type: 'input';
};

export type autocompleteConfigType = baseFieldConfigType & {
  type: 'autocomplete';
  endpoint: AutocompleteEndpoints;
};

export type fieldConfigType =
  | categoryConfigType
  | categoryResidentialConfigType
  | categoryInternationalConfigType
  | rangeConfigType
  | selectConfigType
  | placeConfigType
  | inputConfigType
  | autocompleteConfigType;

export type formConfigType = fieldConfigType[];
