import { useTranslations } from '@pepita-react/i18n';
import Link from 'next/link';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectLink,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import type { LastSearchesItem } from 'src/types/last-thing';
import { LAST_THINGS_TYPES } from 'src/types/last-thing';

import { useLastThingSearches } from '../../hooks/useLastThingSearches';
import { LastThingsSkeleton } from '../LastThingSkeleton';
import { LastThingSlider } from '../LastThingSlider';
import { LastThingTitle } from '../LastThingTitle';

import css from './styles.module.scss';

export function LastSearchesSection({
  customClass,
  titleClass,
  stripeCustomClass,
}: {
  customClass?: string;
  titleClass?: string;
  stripeCustomClass?: string;
}) {
  const { trans } = useTranslations();
  const { lastSearchesData, fetched, isAuthenticated } = useLastThingSearches();

  const lastSearchesSlides = getLasSearchstItemSlides(
    lastSearchesData?.items || []
  );

  return (
    <section
      className={customClass}
      data-cy={`last-things-${LAST_THINGS_TYPES.SEARCHES}`}
    >
      {fetched ? (
        <>
          <LastThingTitle
            customClass={titleClass}
            isAuthenticated={isAuthenticated}
            link="/utente/ricerche/recenti/"
            linkRef={trans('act_see_all_fe')}
            text={trans('lbl_recent_searches')}
            icon="history"
            isVisible={Boolean(lastSearchesData?.count)}
          />
          <LastThingSlider
            type={LAST_THINGS_TYPES.SEARCHES}
            stripeCustomClass={stripeCustomClass}
          >
            {lastSearchesSlides}
          </LastThingSlider>
        </>
      ) : (
        <LastThingsSkeleton
          customClass={stripeCustomClass}
          titleClass={titleClass}
        />
      )}
    </section>
  );
}

const getLasSearchstItemSlides = (items: LastSearchesItem[]) => {
  return items.map((lastThing, index) => {
    const titles = lastThing.title ? [lastThing.title] : [];

    if (lastThing.typologies) {
      titles.push(lastThing.typologies);
    }

    return (
      <PepitaStripe.Slide key={index}>
        <Link href={lastThing.url} passHref legacyBehavior>
          <PepitaMediaObjectLink
            target="_blank"
            customClass={css['hp-lastSearches__card']}
            hoverable
            padded
          >
            <PepitaFigure
              as="figure"
              figureContent={lastThing.figure}
              ratio="square"
              contentFull
              imageCustomClass={css['hp-lastSearches__figure']}
            />
            <PepitaMediaObjectContent
              customClass={css['hp-lastSearches__content']}
            >
              <PepitaMediaObjectTop>
                <PepitaMediaObjectTitle
                  customClass={css['hp-lastSearches__title']}
                >
                  {titles.map((title, i) => (
                    <span
                      key={i}
                      className={css['hp-lastSearches__titleElement']}
                    >
                      {title}
                    </span>
                  ))}
                </PepitaMediaObjectTitle>
                <p className={css['hp-lastSearches__subtitle']}>
                  {lastThing.subTitle}
                </p>
              </PepitaMediaObjectTop>
              <PepitaMediaObjectBottom
                customClass={css['hp-lastSearches__bottom']}
              >
                {lastThing.rangePrice && <p>{lastThing.rangePrice}</p>}
                {lastThing.rangeSurface && <p>{lastThing.rangeSurface}</p>}
              </PepitaMediaObjectBottom>
            </PepitaMediaObjectContent>
          </PepitaMediaObjectLink>
        </Link>
      </PepitaStripe.Slide>
    );
  });
};
