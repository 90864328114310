import type { ReactNode } from 'react';
import { Fragment } from 'react';
import { clsx } from 'clsx';

import { Dossier } from 'src/components/Dossier';
import { DossierSkeleton } from 'src/components/Dossier/DossierSkeleton';

import { useLatestDossier } from 'src/hooks/useDossiers';

import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

import css from './styles.module.scss';

export function ListOfDossier({
  title,
  titleClass,
  customClass,
}: {
  title: ReactNode;
  titleClass?: string;
  customClass?: string;
}) {
  const { data, isFetching } = useLatestDossier(4);

  if (data) {
    return (
      <div className={clsx(customClass)}>
        <p className={titleClass}>{title}</p>
        <div className={css['in-listOfDossier__wrapper']}>
          {data.map((item) => {
            return <Dossier dossier={item} key={item.id} />;
          })}
        </div>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className={clsx(customClass)}>
        <PepitaSkeleton type="text" items={1} customClass={titleClass} />
        <div className={css['in-listOfDossier__wrapper']}>
          {Array.from(Array(4), (_, i) => (
            <Fragment key={i}>
              <DossierSkeleton />
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  return null;
}
