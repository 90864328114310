import { useMemo } from 'react';
import { useAtom } from 'jotai';

import { homepageFormKeyAtom } from '../components/SearchBox/atoms/homepageFormKeyAtom';
import { getHomepageFormKey } from '../components/SearchBox/config/formConfig';
import { HOMEPAGE_FORM_KEYS } from '../components/SearchBox/types';

import { useIsFirstRender } from 'src/hooks/useIsFirstRender';

import type { PageContent } from 'src/types/homepage';

export const useResetHomepageFormKey = (pageContent: PageContent) => {
  const [homepageFormKey, setHomepageFormKey] = useAtom(homepageFormKeyAtom);

  const isFirstRender = useIsFirstRender();

  useMemo(() => {
    if (!isFirstRender) return;

    const correctHomepageFormKey = getHomepageFormKey(pageContent);

    if (correctHomepageFormKey !== homepageFormKey) {
      setHomepageFormKey(correctHomepageFormKey ?? HOMEPAGE_FORM_KEYS.SALE);
    }
  }, [homepageFormKey, isFirstRender, pageContent, setHomepageFormKey]);
};
